import { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { setupInterceptors } from "./axiosConfig";
import { useUserDispatch, signOut } from "../context/UserContext";
import { useUserHomeDispatch, signOut as signOutHome } from "../context/UserHomeContext";

export function InjectAxiosInterceptors() {
    const history = useHistory()
    const dispatch = useUserDispatch();
    const dispatchHome = useUserHomeDispatch();

    const signOutProps = {
        history,
        dispatch,
        dispatchHome,
        signOut,
        signOutHome,
    }

    useEffect(() => {
        setupInterceptors(signOutProps)
        //eslint-disable-next-line
    }, [history])

    // not rendering anything
    return null
}