import React from "react";
import PageTitle from "../../components/PageTitle/PageTitle";

export default function ChangePassword() {
    return (
        <>
            <PageTitle title="Change Password" />
        </>
    );
}
