import React, { useEffect, useState } from "react";
import {
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { ArrowDropDown, ArrowDropUp, Inbox as InboxIcon } from "@material-ui/icons";
import { Link } from "react-router-dom";
import classnames from "classnames";

import useStyles from "./styles";
import Dot from "../Dot";

export default function SidebarLink({
  link,
  icon,
  label,
  children,
  location,
  isSidebarOpened,
  nested,
  type,
  isCollapseOpen
}) {
  const classes = useStyles();
  const [isCollapseToggle, setIsCollapseToggle] = useState(true);

  useEffect(() => {
    if (isCollapseOpen) {
      setIsCollapseToggle(true)
    }
    else {
      setIsCollapseToggle(false)
    }
  }, [location.pathname, isCollapseOpen])

  const isLinkActive =
    link &&
    (location.pathname === link || location.pathname.indexOf(link) !== -1 || isCollapseOpen);
  if (type === "title")
    return (
      <Typography
        className={classnames(classes.linkText, classes.sectionTitle, {
          [classes.linkTextHidden]: !isSidebarOpened,
        })}
      >
        {label}
      </Typography>
    );

  if (type === "divider") return <Divider className={classes.divider} />;
  if (link && link.includes('http')) {
    return (
      <ListItem
        button
        className={classes.link}
        classes={{
          root: classnames(classes.linkRoot, {
            [classes.linkActive]: isLinkActive && !nested,
            [classes.linkNested]: nested,
          }),
        }}
        disableRipple
      >
        <a className={classes.externalLink} href={link}>
          <ListItemIcon
            className={classnames(classes.linkIcon, {
              [classes.linkIconActive]: isLinkActive,
            })}
          >
            {nested ? <Dot color={isLinkActive && "primary"} /> : icon}
          </ListItemIcon>
          <ListItemText
            classes={{
              primary: classnames(classes.linkText, {
                [classes.linkTextActive]: isLinkActive,
                [classes.linkTextHidden]: !isSidebarOpened,
              }),
            }}
            primary={label}
          />
        </a>
      </ListItem>
    )
  }
  if (!children)
    return (
      <ListItem
        button
        component={link && Link}
        to={link}
        className={classes.link}
        classes={{
          root: classnames(classes.linkRoot, {
            [classes.linkActive]: isLinkActive && !nested,
            [classes.linkNested]: nested,
          }),
        }}
        disableRipple
      >
        <ListItemIcon
          className={classnames(classes.linkIcon, {
            [classes.linkIconActive]: isLinkActive,
          })}
        >
          {nested ? <Dot color={isLinkActive && "primary"} /> : icon}
        </ListItemIcon>
        <ListItemText
          classes={{
            primary: classnames(classes.linkText, {
              [classes.linkTextActive]: isLinkActive,
              [classes.linkTextHidden]: !isSidebarOpened,
            }),
          }}
          primary={label}
        />
      </ListItem>
    );

  return (
    <>
      <ListItem
        button
        component={link && link.split('/')[2] !== location.pathname.split('/')[2] ? Link : "div"}
        onClick={() => { setIsCollapseToggle((prev) => !prev) }}
        className={classes.link}
        to={link}
        disableRipple
      >
        <ListItemIcon
          className={classnames(classes.linkIcon, {
            [classes.linkIconActive]: isLinkActive,
          })}
        >
          {icon ? icon : <InboxIcon />}
        </ListItemIcon>
        <ListItemText
          classes={{
            primary: classnames(classes.linkText, {
              [classes.linkTextActive]: isLinkActive,
              [classes.linkTextHidden]: !isSidebarOpened,
            }),
          }}
          primary={label}
        />
        {children && isSidebarOpened
          ? isSidebarOpened && isCollapseToggle
            ? <div className={`${classes.linkText}`} ><ArrowDropUp /></div>
            : <div className={`${classes.linkText}`} ><ArrowDropDown /></div>
          : null}
      </ListItem>
      {
        children && (
          <Collapse
            in={isSidebarOpened && isCollapseToggle}
            timeout="auto"
            unmountOnExit
            className={classes.nestedList}
          >
            <List component="div" disablePadding>
              {children.map(childrenLink => (
                <SidebarLink
                  key={childrenLink && childrenLink.link}
                  location={location}
                  isSidebarOpened={isSidebarOpened}
                  classes={classes}
                  nested
                  {...childrenLink}
                />
              ))}
            </List>
          </Collapse>
        )
      }
    </>
  );
}
