import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import {
  Home as HomeIcon,
  ArrowBack as ArrowBackIcon,
  AccountBox,
  Description,
  People
} from "@material-ui/icons";
import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import { FaWpforms } from 'react-icons/fa';
import { PiSquaresFourDuotone, PiBuildingsBold } from "react-icons/pi";
import { VscTypeHierarchySub } from "react-icons/vsc";
import { LuCompass } from "react-icons/lu";
import { AiOutlineFileImage } from "react-icons/ai";
import { MdOutlineDescription } from "react-icons/md";

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./components/SidebarLink/SidebarLink";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { routeConfigs } from "../../utils/routeConfig";

function Sidebar({ location }) {
  const classes = useStyles();
  const theme = useTheme();

  const structure = [
    { id: 0, label: 'Dashboard', link: routeConfigs.adminDashboard, icon: <HomeIcon /> },
    {
      id: 1,
      label: 'User Roles',
      icon: <AccountBox />,
      link: routeConfigs.adminUserRoles,
      children: [
        { label: 'View User Roles', link: routeConfigs.adminUserRoles },
        { label: 'Rights and Permissions', link: routeConfigs.adminUserRolesPermissions },
      ],
    },
    {
      id: 2,
      label: 'Users',
      icon: <People />,
      link: routeConfigs.adminUserRequests,
      children: [
        { label: 'User Requests', link: routeConfigs.adminUserRequests },
        { label: 'All Users', link: routeConfigs.adminUserAll },
      ],
    },
    {
      id: 3,
      label: 'Assets Type & Category',
      icon: <PiSquaresFourDuotone className={classes.sidebarIcon} />,
      link: routeConfigs.adminAssetCategory,
      children: [
        { label: 'Asset Category', link: routeConfigs.adminAssetCategory },
        { label: 'Asset Subcategory', link: routeConfigs.adminAssetSubcategory },
        { label: 'Asset Type', link: routeConfigs.adminAssetType },
      ],
    },
    {
      id: 4,
      label: 'Assets',
      icon: <Description />,
      link: routeConfigs.adminAssetsAdd,
      children: [
        { label: 'Add Assets', link: routeConfigs.adminAssetsAdd },
        { label: 'View All Assets', link: routeConfigs.adminAssetsAll },
        { label: 'Collections', link: routeConfigs.adminAssetsCollections },
      ],
    },
    {
      id: 5,
      label: 'AUI Form Fields',
      icon: <FaWpforms className={classes.sidebarIcon} />,
      link: routeConfigs.adminAuiIptcCorefields,
      children: [
        { label: 'IPTC Core Fields', link: routeConfigs.adminAuiIptcCorefields },
        { label: 'Additional Info', link: routeConfigs.adminAuiAdditionalInfo },
      ],
    },
    { id: 6, label: 'Departments', link: routeConfigs.adminDepartments, icon: <VscTypeHierarchySub className={classes.sidebarIcon} /> },
    { id: 7, label: 'Regions', link: routeConfigs.adminRegions, icon: <LuCompass className={classes.sidebarIcon} /> },
    { id: 8, label: 'Business Units', link: routeConfigs.adminBusinessunits, icon: <PiBuildingsBold className={classes.sidebarIcon} /> },
    { id: 9, label: 'Banners', link: routeConfigs.adminBanners, icon: <AiOutlineFileImage className={classes.sidebarIcon} /> },
    {
      id: 10,
      label: 'Pages',
      icon: <Description />,
      link: routeConfigs.adminPagesAll,
      children: [
        { label: 'All Pages', link: routeConfigs.adminPagesAll },
        { label: 'Manage Pages', link: routeConfigs.adminPagesManage },
      ],
    },
    {
      id: 11,
      label: 'CMS Changes',
      icon: <MdOutlineDescription className={classes.sidebarIcon} />,
      link: routeConfigs.adminCmschangesFeaturedassets,
      children: [
        { label: 'Featured Assets', link: routeConfigs.adminCmschangesFeaturedassets },
        { label: 'Curated Gallery', link: routeConfigs.adminCmschangesCuratedgallery },
        { label: 'Search By Filters', link: routeConfigs.adminCmschangesSearchbyfilters },
      ],
    },
  ];

  const { isSidebarOpened } = useLayoutState();
  const layoutDispatch = useLayoutDispatch();

  const [isPermanent, setPermanent] = useState(true);

  useEffect(() => {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      open={isSidebarOpened}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List className={classes.sidebarList}>
        {structure.map(link => {
          const pathsplit = location.pathname.split('/')
          const linksplit = link.link.split('/');
          return (
            <SidebarLink
              key={link.id}
              location={location}
              isSidebarOpened={isSidebarOpened}
              isCollapseOpen={pathsplit[2] === linksplit[2]}
              {...link}
            />
          )
        })}
      </List>
    </Drawer>
  );

  function handleWindowWidthChange() {
    const windowWidth = window.innerWidth;
    const breakpointWidth = theme.breakpoints.values.md;
    const isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
