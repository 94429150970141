import React from "react";
import axios from "axios";
import { routeConfigs } from "../utils/routeConfig";
import {
  removeDataFromLocalStorage,
  retrieveDataFromLocalStorage,
  saveDataInLocalStorage,
  localConfig
} from "../utils/localStorage";
import { apiRoutes } from "../utils/apiRoutes";
import { checkValidEmail } from "../utils/validationUtils";
import axiosConfig from "../utils/axiosConfig";

const UserStateContext = React.createContext();
const UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  const [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!retrieveDataFromLocalStorage(localConfig.adminLoginToken),
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  const context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  const context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

export { UserProvider, useUserState, useUserDispatch, loginUser, signOut };

// ###########################################################

async function loginUser(
  dispatch,
  email,
  password,
  history,
  setIsLoading,
  setError,
  setErrorMessage,
  captchaToken
) {
  setError(false);
  setIsLoading(true);
  setErrorMessage("");
  const isValidEmail = checkValidEmail(email);

  if (!email) {
    setError(true)
    setErrorMessage("Please enter the mandatory email address");
    setIsLoading(false);
  }
  else if (!isValidEmail) {
    setError(true)
    setErrorMessage("Please enter the valid email address");
    setIsLoading(false);
  }
  else if (!password) {
    setError(true)
    setErrorMessage("Please enter the mandatory password field");
    setIsLoading(false);
  }
  else if (!captchaToken) {
    setError(true)
    setErrorMessage("CAPTCHA is mandatory");
    setIsLoading(false);
  }
  else if (!!email && !!password) {
    setTimeout(() => {
      axios.post(apiRoutes.adminLogin, { email, password, admin_login: true })
        .then((response) => {
          saveDataInLocalStorage(localConfig.adminLoginToken, response?.data?.data?.token);
          saveDataInLocalStorage(localConfig.adminEmail, response?.data?.data?.email);
          setError(null);
          dispatch({ type: 'LOGIN_SUCCESS' });
          history.push({ pathname: routeConfigs.adminDashboard });
        })
        .catch((e) => {
          const errMessage = e?.response?.data?.message
          if (e?.response?.status === 409) {
            setErrorMessage("409")
            saveDataInLocalStorage(localConfig.adminLoginToken, e.response.data.token);
          }
          else if (errMessage) {
            setError(true);
            setErrorMessage(errMessage)
          }
          setIsLoading(false);
        });
    }, 2000)

  } else {
    dispatch({ type: "LOGIN_FAILURE" });
    setError(true);
    setIsLoading(false);
  }
}

async function signOut(dispatch, history, email = "") {
  let localEmail = retrieveDataFromLocalStorage(localConfig.adminEmail);
  removeDataFromLocalStorage(localConfig.adminEmail);
  if (email !== "") {
    localEmail = email
  }
  if (localEmail && localEmail !== "") {
    await axiosConfig.post(apiRoutes.adminLogout, { email: localEmail })
      .catch(err => { });
  }
  removeDataFromLocalStorage(localConfig.adminLoginToken);
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  history.push({ pathname: routeConfigs.adminLogin });
}
