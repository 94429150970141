import axios from 'axios';
import { baseurl } from './baseUrl';
import { localConfig, retrieveDataFromLocalStorage } from './localStorage';

const instance = axios.create({
  // .. where we make our configurations
  baseURL: baseurl
});

const isAdminUrl = window.location.pathname.includes("admin");

window.addEventListener("storage", function () {
  const token = retrieveDataFromLocalStorage(localConfig.adminLoginToken);
  const userToken = retrieveDataFromLocalStorage(localConfig.userLoginToken);
  instance.defaults.headers.common['Authorization'] = isAdminUrl ? token : userToken;
}, false);

const token = retrieveDataFromLocalStorage(localConfig.adminLoginToken);
const userToken = retrieveDataFromLocalStorage(localConfig.userLoginToken);
instance.defaults.headers.common['Authorization'] = isAdminUrl ? token : userToken;

export const setupInterceptors = (signOutProps) => {
  const {
    history,
    dispatch,
    dispatchHome,
    signOut,
    signOutHome,
  } = signOutProps;
  instance.interceptors.response.use(function (response) {
    // Optional: Do something with response data
    return response;
  }, function (error) {
    // Do whatever you want with the response error here:
    if (error.response.status === "498" || error.response.status === 498) {
      if (isAdminUrl) {
        signOut(dispatch, history);
      }
      else {
        signOutHome(dispatchHome, history)
      }
    }
    // But, be SURE to return the rejected promise, so the caller still has
    // the option of additional specialized handling at the call-site:
    return Promise.reject(error);
  });
}
export default instance;
