import React from "react";
import PageTitle from "../../components/PageTitle/PageTitle";

export default function AssetsAdd() {
    return (
        <>
            <PageTitle title="Add Assets" />
        </>
    );
}
