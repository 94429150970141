import React, { createRef, useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle/PageTitle";
import axiosConfig from "../../utils/axiosConfig";
import { apiRoutes } from "../../utils/apiRoutes";
import { FaUser } from "react-icons/fa";
import { MdAdminPanelSettings } from "react-icons/md";
import { toast } from "react-toastify";
import { FormControl } from "@material-ui/core";
import useStyles from "./styles";
import { FiPlus, FiMinus } from "react-icons/fi";
import { RiArrowDownSFill } from "react-icons/ri";

export default function UserRolesPermissions() {
  const classes = useStyles();
  const [roleName, setRoleName] = useState("");
  const [permissionsListArray, setPermissionsListArray] = useState([]);
  const [enabled, setEnabled] = useState("");
  const [allRoles, setAllRoles] = useState();
  const [roleSelectValue, setRoleSelectValue] = useState(-1);
  const [allPermissions, setAllPermissions] = useState([]);
  const [currentRoleId, setCurrentRoleId] = useState([]);
  const [showUser, setShowUser] = useState(true);
  const [showAdmin, setShowAdmin] = useState(true);

  useEffect(() => {
    getAllPermissions();
    getRoles();
  }, []);

  function getAllPermissions() {
    axiosConfig
      .get(apiRoutes.adminGetAllPermissions)
      .then((response) => {
        setAllPermissions(response.data.message);
      })
      .catch((error) => {});
  }

  function getRoles() {
    axiosConfig
      .get(apiRoutes.adminGetUserRoles)
      .then((response) => {
        setAllRoles(response.data.role_data);
      })
      .catch((error) => {});
  }

  function getSingleUserRoleData(id) {
    if (id && id !== undefined && id !== null) {
      axiosConfig
        .get(`${apiRoutes.adminGetSingleUserRole}?id=${id}`)
        .then((response) => {
          setPermissionsListArray(response?.data?.role_data[0]?.permissions);
          setRoleName(response.data.role_data[0].role);
          setEnabled(response?.data?.role_data[0]?.enabled);
        })
        .catch((error) => {});
    }
  }

  function handleCheckboxChange(value, isChecked) {
    if (!value.permissionName.includes("View")) {
      const checkViewPermission = permissionsListArray.some(
        (permission) =>
          permission.permissionCategory === value.permissionCategory &&
          permission.permissionSubCategory === value.permissionSubCategory &&
          permission.permissionName.includes("View"),
      );
      if (checkViewPermission === false) {
        toast.error("Please assign permission to view the section first", {
          bodyClassName: "font-color-black",
          autoClose: 8000,
        });
        return false;
      }
    } else if (!isChecked) {
      setPermissionsListArray((prevArray) =>
        prevArray.filter((item) => {
          if (
            item.permissionCategory === value.permissionCategory &&
            item.permissionSubCategory === value.permissionSubCategory
          ) {
            return false;
          } else {
            return true;
          }
        }),
      );
    }

    if (!permissionsListArray || permissionsListArray === null) {
      setPermissionsListArray([]);
    }

    if (isChecked) {
      setPermissionsListArray((prevArray) => [...prevArray, value]);
    } else {
      setPermissionsListArray((prevArray) =>
        prevArray.filter((item) => item._id !== value._id),
      );
    }
  }

  function handleRoleChange(e) {
    setRoleSelectValue(e);
    getSingleUserRoleData(e);
    setCurrentRoleId(e);
  }

  function handlePermissionsChange() {
    if (
      !roleName ||
      roleName.length === 0 ||
      roleName === undefined ||
      roleName === -1
    ) {
      toast.error("Please select a user role.", {
        bodyClassName: "font-color-black",
        autoClose: 8000,
      });
      return false;
    }

    if (!permissionsListArray || permissionsListArray.length === 0) {
      toast.error(
        "At least one permission should be assigned to the user role.",
        {
          bodyClassName: "font-color-black",
          autoClose: 8000,
        },
      );
      return false;
    }

    const body = {
      roleId: currentRoleId,
      role: roleName,
      isenabled: enabled === null ? true : enabled,
      permissions: permissionsListArray,
    };

    axiosConfig
      .put(apiRoutes.adminEditUserRole, body)
      .then((response) => {
        toast("User role updated successfully!", {
          bodyClassName: "sucessToastBody",
          progressClassName: "sucessToastProgress",
          autoClose: 8000,
        });
      })
      .catch((e) => {
        if (e.response.status === "409" || e.response.status === 409) {
          toast.error("Role name must be unique.", {
            bodyClassName: "font-color-black",
            autoClose: 8000,
          });
        }
      });
  }

  return (
    <>
      <div className="permissionsTopSection">
        <PageTitle title="User Roles Rights and Permissions" />
        <div className="permission-common userRolesName">
          <span>User Role Name</span>
          <FormControl
            variant="outlined"
            className={classes.modalSelect}
            size="small"
          >
            <select
              default={roleSelectValue}
              onChange={(i) => handleRoleChange(i.target.value)}
              className="permission-role-select"
            >
              <option value={-1} className="select-none">
                Select
              </option>
              {allRoles?.map((data, i) => {
                if (data?.enabled) {
                  return (
                    <option key={i} value={data._id}>
                      {data.role}
                    </option>
                  );
                }
                return false;
              })}
            </select>
            <div className="selectArrow">
              <RiArrowDownSFill />
            </div>
          </FormControl>
        </div>
      </div>
      <div className="userRolesPermissions-outer">
        <div className="permission-heading permission-user">
          <h2>User Website</h2>
          <div
            className="showhidepermission"
            onClick={() => {
              setShowUser((prev) => !prev);
            }}
          >
            {showUser ? <FiMinus /> : <FiPlus />}
          </div>
        </div>
        <div className={showUser ? "permissionUser active" : "permissionUser"}>
          {allPermissions.length > 1 &&
            allPermissions.map((data, i) => {
              if (data._id.permissionCategory === "User Website") {
                return (
                  <div div className="permission-common" key={i}>
                    <span>{data._id.permissionSubCategory}</span>
                    <div className="permissions-list">
                      {data.permissions.map((perm, ii) => {
                        const permObj = {
                          permissionCategory: data._id.permissionCategory,
                          permissionSubCategory: data._id.permissionSubCategory,
                          permissionName: perm.permissionName,
                          _id: perm._id,
                        };

                        return (
                          <div className="permission-list-inside" key={ii}>
                            <div className="permission-check-div">
                              <input
                                type="checkbox"
                                _id={perm._id}
                                onChange={(e) => {
                                  handleCheckboxChange(
                                    permObj,
                                    e.target.checked,
                                  );
                                }}
                                checked={permissionsListArray?.some(
                                  (e) => e._id === perm._id,
                                )}
                              />
                            </div>
                            <div className="permission-label-div">
                              <label htmlFor={perm._id}>
                                {perm.permissionName}
                              </label>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              }
              return null;
            })}
        </div>
      </div>
      <div className="userRolesPermissions-outer">
        <div className="permission-heading permission-admin">
          <h2>Administrator Access</h2>
          <div
            className="showhidepermission"
            onClick={() => {
              setShowAdmin((prev) => !prev);
            }}
          >
            {showAdmin ? <FiMinus /> : <FiPlus />}
          </div>
        </div>

        <div
          className={showAdmin ? "permissionAdmin active" : "permissionAdmin"}
        >
          {allPermissions.length > 1 &&
            allPermissions.map((data, i) => {
              if (data._id.permissionCategory === "Admin Access") {
                return (
                  <div className="permission-common" key={i}>
                    <span>{data._id.permissionSubCategory}</span>
                    <div className="permissions-list">
                      {data.permissions.map((perm, ii) => {
                        const permObj = {
                          permissionCategory: data._id.permissionCategory,
                          permissionSubCategory: data._id.permissionSubCategory,
                          permissionName: perm.permissionName,
                          _id: perm._id,
                        };
                        return (
                          <div className="permission-list-inside" key={ii}>
                            <div className="permission-check-div">
                              <input
                                type="checkbox"
                                _id={perm._id}
                                onChange={(e) => {
                                  handleCheckboxChange(
                                    permObj,
                                    e.target.checked,
                                  );
                                }}
                                checked={permissionsListArray?.some(
                                  (e) => e._id === perm._id,
                                )}
                              />
                            </div>
                            <div className="permission-label-div">
                              <label htmlFor={perm._id}>
                                {perm.permissionName}
                              </label>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              }
              return null;
            })}
        </div>
      </div>
      <div className="userRolesPermissions-outer lastDiv">
        <div className="formButtons">
          <button
            type="button"
            className="permission-submit-button"
            onClick={handlePermissionsChange}
          >
            Save
          </button>
          <button
            type="button"
            className="permission-submit-button"
            onClick={() => handleRoleChange(roleSelectValue)}
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}
