import React from "react";
import PageTitle from "../../components/PageTitle/PageTitle";

export default function AssetsAll() {
    return (
        <>
            <PageTitle title="View All Assets" />
        </>
    );
}
