import React from "react";
import {
  Route,
  Switch,
  withRouter,
} from "react-router-dom";
import classnames from "classnames";
import { Box } from '@material-ui/core';
import useStyles from "./styles";
import Header from "../Header";
import Sidebar from "../Sidebar";
import Dashboard from "../../pages/dashboard";
import { useLayoutState } from "../../context/LayoutContext";
import { routeConfigs } from "../../utils/routeConfig";
import UserRoles from "../../pages/user-roles/UserRoles";
import UsersRequests from "../../pages/users/UsersRequests";
import UsersAll from "../../pages/users/UsersAll";
import AssetCategory from "../../pages/asset-type-category/AssetCategory";
import AssetSubCategory from "../../pages/asset-type-category/AssetSubCategory";
import AssetType from "../../pages/asset-type-category/AssetType";
import AssetsAdd from "../../pages/assets/AssetsAdd";
import AssetsAll from "../../pages/assets/AssetsAll";
import AssetsCollections from "../../pages/assets/AssetsCollections";
import IptcCoreFields from "../../pages/aui-form-fields/IptcCoreFields";
import AdditionalInfo from "../../pages/aui-form-fields/AdditionalInfo";
import Departments from "../../pages/departments/Departments";
import Regions from "../../pages/regions/Regions";
import BusinessUnits from "../../pages/business-units/BusinessUnits";
import Banners from "../../pages/banners/Banners";
import PagesAll from "../../pages/pages-cms/PagesAll";
import PagesManage from "../../pages/pages-cms/PagesManage";
import FeaturedAssets from "../../pages/cms-changes/FeaturedAssets";
import CuratedGallery from "../../pages/cms-changes/CuratedGallery";
import SearchByFilters from "../../pages/cms-changes/SearchByFilters";
import ProfileSettings from "../../pages/profile-pages/ProfileSettings";
import ChangePassword from "../../pages/profile-pages/ChangePassword";
import UserRolesPermissions from "../../pages/user-roles/UserRolesPermissions";

function Layout(props) {
  const classes = useStyles();
  const layoutState = useLayoutState();

  return (
    <div className={classes.root}>
      <>
        <Header history={props.history} />
        <Sidebar />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          })}
        >
          <div className={classes.fakeToolbar} />
          <Switch>
            {/* sidebar menu routes */}
            <Route path={routeConfigs.adminDashboard} component={Dashboard} />
            <Route exact path={routeConfigs.adminUserRoles} component={UserRoles} />
            <Route exact path={routeConfigs.adminUserRolesPermissions} component={UserRolesPermissions} />
            <Route exact path={routeConfigs.adminUserRequests} component={UsersRequests} />
            <Route exact path={routeConfigs.adminUserAll} component={UsersAll} />
            <Route exact path={routeConfigs.adminAssetCategory} component={AssetCategory} />
            <Route exact path={routeConfigs.adminAssetSubcategory} component={AssetSubCategory} />
            <Route exact path={routeConfigs.adminAssetType} component={AssetType} />
            <Route exact path={routeConfigs.adminAssetsAdd} component={AssetsAdd} />
            <Route exact path={routeConfigs.adminAssetsAll} component={AssetsAll} />
            <Route exact path={routeConfigs.adminAssetsCollections} component={AssetsCollections} />
            <Route exact path={routeConfigs.adminAuiIptcCorefields} component={IptcCoreFields} />
            <Route exact path={routeConfigs.adminAuiAdditionalInfo} component={AdditionalInfo} />
            <Route exact path={routeConfigs.adminDepartments} component={Departments} />
            <Route exact path={routeConfigs.adminRegions} component={Regions} />
            <Route exact path={routeConfigs.adminBusinessunits} component={BusinessUnits} />
            <Route exact path={routeConfigs.adminBanners} component={Banners} />
            <Route exact path={routeConfigs.adminPagesAll} component={PagesAll} />
            <Route exact path={routeConfigs.adminPagesManage} component={PagesManage} />
            <Route exact path={routeConfigs.adminCmschangesFeaturedassets} component={FeaturedAssets} />
            <Route exact path={routeConfigs.adminCmschangesCuratedgallery} component={CuratedGallery} />
            <Route exact path={routeConfigs.adminCmschangesSearchbyfilters} component={SearchByFilters} />
            {/* profile routes */}
            <Route exact path={routeConfigs.adminProfileSettings} component={ProfileSettings} />
            <Route exact path={routeConfigs.adminChangePassword} component={ChangePassword} />
          </Switch>
          <Box
            mt={5}
            width={"100%"}
            display={"flex"}
            alignItems={"center"}
            justifyContent="space-between"
          />
        </div>
      </>
    </div>
  );
}

export default withRouter(Layout);
