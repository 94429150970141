export function saveDataInLocalStorage(localStorageName, data) {
    const base64Data = btoa(JSON.stringify(data));
    localStorage.setItem(localStorageName, base64Data);
    window.dispatchEvent(new Event("storage"));
}

export function retrieveDataFromLocalStorage(localStorageName) {
    const base64Data = localStorage.getItem(localStorageName);
    if (base64Data === null) {
        return false;
    }
    try {
        return JSON.parse(atob(base64Data));
    } catch (error) {
        return false;
    }
}

export function removeDataFromLocalStorage(key) {
    if (localStorage.getItem(key)) {
        localStorage.removeItem(key);
    }
}

export const localConfig = {
    adminLoginToken: "adminLoginToken",
    adminEmail: "adminEmail",
    userLoginToken: "userLoginToken",
    userEmail: "userEmail",
}