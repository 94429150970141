/* eslint-disable */
import { passwordStrength } from 'check-password-strength';

export function checkValidEmail(email) {
    const pattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    return email.match(pattern);
}

export const specialCharsRegex = /[`!@#$%^&*()_+\-=\[\]{};':"\|,.<>\/?~]/;
export const numberRegex = /[0-9]/;
export const lowerAlphabeticRegex = /^(?=.*[a-z])/;
export const upperAlphabeticRegex = /^(?=.*[A-Z])/;
export const onlyAlphabetsSpaceRegex = /^[A-Za-z\s]+$/;


export function checkPasswordStrength(password) {
    const regCheckLength = /^.{8,21}$/;

    const passId = passwordStrength(password);

    if (!password || password.length === 0) {
        return "Too Short";
    }
    if (passId.id === 0) {
        return "Weak";
    }
    if (passId.id >= 1) {
        if (regCheckLength.test(password)) {
            if (passId.id > 2 && numberRegex.test(password) && specialCharsRegex.test(password) && (upperAlphabeticRegex.test(password) || lowerAlphabeticRegex.test(password))) {
                return "Strong";
            }
            if ((upperAlphabeticRegex.test(password) || lowerAlphabeticRegex.test(password)) && (numberRegex.test(password) || specialCharsRegex.test(password))) {
                return "Good";
            }
        }
        if (regCheckLength.test(password) || numberRegex.test(password) || specialCharsRegex.test(password)) {
            return "Medium";
        }
        return passwordStrength(password).value;
    }
    return "Weak";
}
