import React from "react";
import PageTitle from "../../components/PageTitle/PageTitle";

export default function AssetSubCategory() {
    return (
        <>
            <PageTitle title="Asset Sub Category" />
        </>
    );
}
