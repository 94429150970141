import tinycolor from "tinycolor2";

const primary = "#0056B8";
const secondary = "#FF5C93";
const warning = "#FFC260";
const success = "#3CD4A0";
const info = "#3BAE86F9";

const lightenRate = 7.5;
const darkenRate = 15;

const defaultTheme = {
  palette: {
    primary: {
      main: primary,
      light: tinycolor(primary)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(primary)
        .darken(darkenRate)
        .toHexString(),
    },
    secondary: {
      main: secondary,
      light: tinycolor(secondary)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(secondary)
        .darken(darkenRate)
        .toHexString(),
      contrastText: "#FFFFFF",
    },
    warning: {
      main: warning,
      light: tinycolor(warning)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(warning)
        .darken(darkenRate)
        .toHexString(),
    },
    success: {
      main: success,
      light: tinycolor(success)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(success)
        .darken(darkenRate)
        .toHexString(),
    },
    info: {
      main: info,
      light: tinycolor(info)
        .lighten(lightenRate)
        .toHexString(),
      dark: tinycolor(info)
        .darken(darkenRate)
        .toHexString(),
    },
    text: {
      primary: "#4A4A4A",
      secondary: "#6E6E6E",
      hint: "#B9B9B9",
    },
    background: {
      default: "#F6F7FF",
      light: "#F3F5FF",
    },
  },
  customShadows: {
    widget:
      "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    widgetDark:
      "0px 3px 18px 0px #4558A3B3, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
    widgetWide:
      "0px 12px 33px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
  },
  overrides: {
    MuiBackdrop: {
      root: {
        backgroundColor: "#4A4A4A1A",
      },
    },
    MuiMenu: {
      paper: {
        boxShadow:
          "0px 3px 11px 0px #E8EAFC, 0 3px 3px -2px #B2B2B21A, 0 1px 8px 0 #9A9A9A1A",
      },
    },
    MuiSelect: {
      icon: {
        color: "#B9B9B9",
      },
    },
    MuiListItem: {
      root: {
        "&$selected": {
          backgroundColor: "#F3F5FF !important",
          "&:focus": {
            backgroundColor: "#F3F5FF",
          },
        },
      },
      button: {
        "&:hover, &:focus": {
          backgroundColor: "#F3F5FF",
        },
      },
    },
    MuiTouchRipple: {
      child: {
        backgroundColor: "white",
      },
    },
    MuiTableRow: {
      root: {
        height: 56,
      },
    },
    MuiTableCell: {
      root: {
        borderBottom: "none",
        paddingLeft: 24,
        "&:has(.datatables-noprint)": {
          textAlign: '-webkit-center'
        },
        paddingTop: 0,
        paddingBottom: 0,
      },

      body: {
        fontSize: "0.95rem",
      },

    },
    MuiTablePagination: {
      root: {
        "& button#pagination-back": {
          border: "1px solid #ddd",
          padding: " 5px"
        },
        "& button#pagination-next": {
          border: "1px solid #ddd",
          padding: "5px",
          marginLeft: "8px"
        }
      }
    },
    MuiPaper: {
      root: {
        "&:has(.popup-main)": {
          borderRadius: "0px"
        }
      }
    },
    MUIDataTableHeadCell: {
      root: {
        fontWeight: 700,
        fontSize: "16px",
      },
      sortAction: {
        fontWeight: 700,
        fontSize: "17px",
        display: "flex",
        alignItems: "center",
        "& svg": {
          color: "#ffffff !important",
        }
      }, sortActive: {
        color: "#ffffff",
      }, data: {
        color: "#ffffff",
      },

    },
    MuiTableSortLabel: {
      root: {
        width: "1rem",
      },
    },
    PrivateSwitchBase: {
      root: {
        marginLeft: 10
      }
    },
    MUIDataTableBodyCell: {
      root: {
        // maxWidth: "150px",
        // "& input[type='text']": {
        // }
      },
    },
    MUIDataTablePagination: {
      root: {

      },
    },
    MuiSwitch: {
      root: {
        width: "50px",
        height: "23px",
        padding: "0px"
      },
      switchBase: {
        color: "#818181",
        padding: "1px",
        "&$checked": {
          "& + $track": {
            backgroundColor: "#37cb90",
            "&:after": {
              content: "'ON'",
              left: "6px",
            },
          }
        }
      },
      thumb: {
        color: "white",
        width: "20px",
        height: "20px",
        margin: "1px"
      },
      track: {
        borderRadius: "15px",
        backgroundColor: "#707070",
        opacity: "1 !important",
        "&:after, &:before": {
          color: "white",
          fontSize: "11px",
          position: "absolute",
          top: "6px",
          fontWeight: "600"
        },
        "&:before": {
          right: "6px"
        }
      }
    }
  }
};

export default defaultTheme;
