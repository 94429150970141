import React, { useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle/PageTitle";
import {
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import axiosConfig from "../../utils/axiosConfig";
import { apiRoutes } from "../../utils/apiRoutes";

import { toast } from "react-toastify";
import { AiOutlineSearch } from "react-icons/ai";
import { ImEye } from "react-icons/im";
import { LuTrash2 } from "react-icons/lu";
import UserRequestDetails from "./userRequestDetails";
import Tooltip from "@material-ui/core/Tooltip";
import { CustomIcons } from "../CustomIcons";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
export default function UsersRequests() {
  const [searchText, setSearchText] = useState("");
  const [requestData, setRequestData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [bulkDeleteConfirmPop, setBulkDeleteConfirmPop] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [rowsToBeDeleted, setRowsToBeDeleted] = useState([]);
  const [requestDetailsPop, setRequestDetailsPop] = useState(false);
  const [deleteConfirmPop, setDeleteConfirmPop] = useState(false);
  const [currentRowData, setCurrentRowData] = useState("");
  const [currentUserEmail, setCurrentUserEmail] = useState();

  const [allRegion, setAllRegion] = useState([]);
  const [allDepartment, setAllDepartment] = useState([]);
  const [allUserRole, setAllUserRole] = useState([]);

  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [sortBy, setSortBy] = useState("DEPARTMENT");
  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {
    getUserRequests();
    getOtherData();
  }, []);

  function getOtherData() {
    axiosConfig
      .get(`${apiRoutes.adminGetRegion}?limit=5000`)
      .then((response) => {
        setAllRegion(response.data.regions);
      })
      .catch((error) => {});
    axiosConfig
      .get(`${apiRoutes.adminGetDepartment}?limit=5000`)
      .then((response) => {
        setAllDepartment(response.data.departments);
      })
      .catch((error) => {});
    axiosConfig
      .get(`${apiRoutes.adminGetUserRoles}?limit=5000`)
      .then((response) => {
        setAllUserRole(response.data.role_data);
      })
      .catch((error) => {});
  }

  function handleUserReqDetail(data) {
    setRequestDetailsPop(true);
    setCurrentUserEmail(data[2]);
  }

  function getUserRequests(rep = false) {
    if (!rep) setLoading(true);
    axiosConfig
      .get(apiRoutes.adminGetUserRequests)
      .then((response) => {
        setRequestData(response.data.userDataArray);
        setLoading(false);
      })
      .catch((e) => {});
  }

  function handleBulkDelete() {
    const body = {
      userIds: rowsToBeDeleted,
    };
    axiosConfig
      .post(apiRoutes.userRequestsBulkDelete, body)
      .then((response) => {
        toast("Users deleted sucessfully!", {
          bodyClassName: "sucessToastBody",
          progressClassName: "sucessToastProgress",
          autoClose: 8000,
        });
        getUserRequests(true);
      })
      .catch((e) => {});
    setBulkDeleteConfirmPop(false);
    setSelectedRows([]);
  }

  function handleSingleDelete(id) {
    const body = {
      userIds: [id],
    };
    axiosConfig
      .post(apiRoutes.userRequestsBulkDelete, body)
      .then((response) => {
        toast("User deleted sucessfully!", {
          bodyClassName: "sucessToastBody",
          progressClassName: "sucessToastProgress",
          autoClose: 8000,
        });
        getUserRequests(true);
      })
      .catch((e) => {});
    setDeleteConfirmPop(false);
  }

  const columns = [
    {
      name: "first_name",
      label: "First Name",
      options: {
        filter: true,
        sortThirdClickReset: true,
        search: true,

        customHeadRender: (columnMeta) => {
          return (
            <th className="leftAlign">
              {columnMeta.label}
              {sortOrder === "asc" ? (
                <IconButton
                  onClick={() => {
                    handleSortDescending();
                  }}
                >
                  <ArrowUpwardIcon />
                </IconButton>
              ) : (
                <IconButton
                  onClick={() => {
                    handleSortAscending();
                  }}
                >
                  <ArrowDownwardIcon />
                </IconButton>
              )}
            </th>
          );
        },

        customBodyRender: (columnMeta) => {
          return <td className="leftAlign">{columnMeta}</td>;
        },
      },
    },
    {
      name: "last_name",
      label: "Last Name",
      options: {
        filter: true,
        sortThirdClickReset: true,
        search: true,
        customHeadRender: (columnMeta) => {
          return <th className="leftAlign">{columnMeta.label}</th>;
        },
        customBodyRender: (columnMeta) => {
          return <td className="leftAlign">{columnMeta}</td>;
        },
      },
    },
    {
      name: "email",
      label: "Email",
      options: {
        filter: true,
        sortThirdClickReset: true,
        search: false,
        customHeadRender: (columnMeta) => {
          return <th className="leftAlign">{columnMeta.label}</th>;
        },
        customBodyRender: (columnMeta) => {
          return <td className="leftAlign">{columnMeta}</td>;
        },
      },
    },
    {
      name: "Action",
      options: {
        filter: false,
        searchable: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          return (
            <Grid container className="actionIcons">
              <Tooltip title="View">
                <Grid item className="pencil-icon">
                  <ImEye
                    className="gridEditIconUsers"
                    onClick={() => handleUserReqDetail(tableMeta.rowData)}
                  />
                </Grid>
              </Tooltip>
              <Tooltip title="Delete">
                <Grid item>
                  <LuTrash2
                    className="gridEditIconUsers"
                    onClick={() => {
                      setDeleteConfirmPop(true);
                      setCurrentRowData(tableMeta?.rowData);
                    }}
                  />
                </Grid>
              </Tooltip>
            </Grid>
          );
        },
      },
    },
    {
      name: "_id",
      options: {
        display: false,
        filter: false,
        sort: false,
        viewColumns: false,
        searchable: false,
      },
    },
  ];

  const options = {
    filterType: "checkbox",
    download: false,
    print: false,
    filter: false,
    pagination: false,

    onRowsDelete: (rowsSelectedData) => {
      setBulkDeleteConfirmPop(true);
      const bulkDeleteIds = [];
      const selectRows = [];
      rowsSelectedData.data.map((data) => {
        bulkDeleteIds.push(requestData[data.dataIndex]._id);
        selectRows.push(data.dataIndex);
        return false;
      });
      setSelectedRows(selectRows);
      setRowsToBeDeleted(bulkDeleteIds);
    },
    rowsSelected: selectedRows,
    setCellProps: () => ({ style: { maxWidth: "159px" } }),
    setTableProps: () => ({ className: "custom-table-design" }),
    searchText,
    search: false,
    viewColumns: false,
    textLabels: {
      body: {
        noMatch: "No data found",
      },
      pagination: false,
    },
  };

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setLimit(+newRowsPerPage);
    getUserRequests(1, +newRowsPerPage);
    setCurrentPage(1);
  };

  const handleFetchPageData = (newpage, newlimit) => {
    getUserRequests(newpage, limit);
  };

  function searchTextData(e) {
    let user_column = e;
    setSearchText(user_column);
    axiosConfig
      .get(apiRoutes.adminGetUserRequests, {
        params: {
          search: user_column != "" ? user_column : null,
          limit: limit,
        },
      })
      .then((response) => {
        setRequestData(response.data.userDataArray);

        setLoading(false);
      })
      .catch((error) => {
        console.error("API Error:", error);
        setLoading(false);
      });
  }

  const handleSortAscending = () => {
    axiosConfig
      .get(apiRoutes.adminGetUserRequests, {
        params: { sortBy: "first_name", sortOrder: "asc" },
      })
      .then((response) => {
        const depts = response?.data?.userDataArray;

        setPage(response.data.currentPage);
        setRequestData(depts);
        setSortBy(response?.data?.sortBy);
        setSortOrder(response?.data?.sortOrder);
        setLoading(false);
      })
      .catch((error) => {
        console.error("API Error:", error);
        setLoading(false);
      });
  };

  const handleSortDescending = () => {
    axiosConfig
      .get(apiRoutes.adminGetUserRequests, {
        params: { sortBy: "first_name", sortOrder: "desc" },
      })
      .then((response) => {
        const depts = response?.data?.userDataArray;

        setPage(response.data.currentPage);
        setRequestData(depts);
        setSortBy(response?.data?.sortBy);
        setSortOrder(response?.data?.sortOrder);
        setLoading(false);
      })
      .catch((error) => {
        console.error("API Error ", error);
        setLoading(false);
      });
  };

  return (
    <>
      <PageTitle title="Users Requests" />
      <div className="table-upper">
        <div className="search_table">
          <AiOutlineSearch />
          <input
            id="standard-basic"
            label="Search"
            value={searchText}
            placeholder="Search"
            onChange={(e) => searchTextData(e.target.value)}
            // onChange={(e) => setSearchText(e.target.value)}
            maxLength={50}
          />
        </div>
      </div>
      <Grid container spacing={4} className={"userReq-table"}>
        <Grid item xs={12}>
          {!loading && (
            <MUIDataTable
              data={requestData ? requestData : []}
              columns={columns}
              className="custom-table-outer"
              options={options}
            />
          )}

          <CustomIcons
            totalPages={totalPages}
            setTotalPages={setTotalPages}
            setPage={setPage}
            pageNew={page}
            onRowsPerPageChange={handleRowsPerPageChange}
            handleFetchPageData={handleFetchPageData}
          />
        </Grid>
      </Grid>
      <Dialog
        open={bulkDeleteConfirmPop}
        onClose={() => setBulkDeleteConfirmPop(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={false}
        maxWidth={"sm"}
      >
        <div className="popup-main">
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              className="popup-text"
            >
              Are you sure you want to delete the selected user requests?
            </DialogContentText>
          </DialogContent>
          <DialogActions className={"popup-button-outer"}>
            <Button
              variant="contained"
              className={"btn-design-theme yes-button"}
              onClick={handleBulkDelete}
            >
              Yes
            </Button>
            <Button
              variant="contained"
              className="btn-design-theme cancel-button"
              onClick={() => {
                setBulkDeleteConfirmPop(false);
                setSelectedRows([]);
              }}
            >
              No
            </Button>
          </DialogActions>
        </div>
      </Dialog>
      <Dialog
        open={deleteConfirmPop}
        onClose={() => setDeleteConfirmPop(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={false}
        maxWidth={"sm"}
      >
        <div className="popup-main">
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              className="popup-text"
            >
              Are you sure you want to delete this user request?
            </DialogContentText>
          </DialogContent>
          <DialogActions className={"popup-button-outer"}>
            <Button
              variant="contained"
              className={"btn-design-theme yes-button"}
              onClick={() => handleSingleDelete(currentRowData[4])}
            >
              Yes
            </Button>
            <Button
              variant="contained"
              className="btn-design-theme cancel-button"
              onClick={() => {
                setDeleteConfirmPop(false);
              }}
            >
              No
            </Button>
          </DialogActions>
        </div>
      </Dialog>
      <Dialog
        open={requestDetailsPop}
        onClose={() => setRequestDetailsPop(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={false}
        maxWidth={"sm"}
      >
        <UserRequestDetails
          currentUserEmail={currentUserEmail}
          allRegion={allRegion}
          allDepartment={allDepartment}
          allUserRole={allUserRole}
          setRequestDetailsPop={setRequestDetailsPop}
          getUserRequests={getUserRequests}
        />
      </Dialog>
    </>
  );
}
