import { Button } from '@material-ui/core';
import React from 'react'
import { signOut, useUserHomeDispatch } from '../../context/UserHomeContext';

function Home(props) {
    const dispatch = useUserHomeDispatch();
    return (
        <div>HomePage<Button variant="outlined" onClick={() => { signOut(dispatch, props.history) }}>Logout</Button></div>
    )
}

export default Home;