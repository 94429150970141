import React, { useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle/PageTitle";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
} from "@material-ui/core";
import { FaPencilAlt, FaCheck, FaTimes } from "react-icons/fa";
import useStyles from "./styles";
import { apiRoutes } from "../../utils/apiRoutes";
import axiosConfig from "../../utils/axiosConfig";
import MUIDataTable from "mui-datatables";
import { toast } from "react-toastify";
import { AiOutlineSearch } from "react-icons/ai";
import { CustomIcons } from "../CustomIcons";
import Tooltip from "@material-ui/core/Tooltip";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import IconButton from "@mui/material/IconButton";
import { RxCross2 } from "react-icons/rx";

export default function Departments() {
  const classes = useStyles();
  const [departmentData, setDepartmentData] = useState();
  const [inEditingMode, setInEditingMode] = useState(false);
  const [inlineValue, setInlineValue] = useState("");
  const [open, setOpen] = useState(false);
  const [newDepartment, setNewDepartment] = useState("");
  const [currentRowData, setCurrentRowData] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [sortBy, setSortBy] = useState("DEPARTMENT");
  const [sortOrder, setSortOrder] = useState("asc");

  {
    /* called getDepartments function inside useEffect and function is called every time on change of page and limit */
  }
  useEffect(() => {
    getDepartments();
  }, []);

  function getDepartments(newPage = page, limitN = limit, rep = false) {
    if (!rep) setLoading(true);

    axiosConfig
      .get(apiRoutes.adminGetDepartment, {
        params: { page: newPage, limit: limitN },
      })
      .then((response) => {
        const depts = response?.data?.departments;
        const tabledata = depts?.map((data, i) => {
          return [
            response?.data?.limit * (response?.data.currentPage - 1) + i + 1,
            data.department_name,
            data.enabled,
            data._id,
          ];
        });

        setDepartmentData(tabledata);
        setPage(response.data.currentPage);
        setTotalPages(response.data.totalPages);
        setLimit(response.data.limit);
        setLoading(false);
      })
      .catch((error) => {
        console.error("API Error:", error);
        setLoading(false);
      });
  }

  const handleFetchPageData = (newpage, newlimit) => {
    console.log("newpage", newpage, "limit");
    getDepartments(newpage, limit);
  };

  function searchTextData(e) {
    let depname = e;
    axiosConfig
      .get(apiRoutes.adminGetDepartment, {
        params: { search: depname != "" ? depname : null, limit: limit },
      })
      .then((response) => {
        const depts = response?.data?.departments;

        const tabledata = depts?.map((data, i) => {
          return [i + 1, data.department_name, data.enabled, data._id];
        });

        setDepartmentData(tabledata);

        setLoading(false);
      })
      .catch((error) => {
        console.error("API Error:", error);
        setLoading(false);
      });
  }

  const handleRowsPerPageChange = (newRowsPerPage) => {
    console.log("newRowsPerPage", newRowsPerPage);
    setLimit(+newRowsPerPage);
    getDepartments(1, +newRowsPerPage);
    setCurrentPage(1);
  };

  console.log("limit", limit);

  const handleSortAscending = () => {
    axiosConfig
      .get(apiRoutes.adminGetDepartment, {
        params: { sortBy: "department_name", sortOrder: "asc" },
      })
      .then((response) => {
        const depts = response?.data?.departments;
        const tabledata = depts?.map((data, i) => {
          return [i + 1, data.department_name, data.enabled, data._id];
        });
        setPage(response.data.currentPage);
        setDepartmentData(tabledata);
        setSortBy(response?.data?.sortBy);
        setSortOrder(response?.data?.sortOrder);
        setLoading(false);
      })
      .catch((error) => {
        console.error("API Error:", error);
        setLoading(false);
      });
  };

  const handleSortDescending = () => {
    axiosConfig
      .get(apiRoutes.adminGetDepartment, {
        params: { sortBy: "department_name", sortOrder: "desc" },
      })
      .then((response) => {
        const depts = response?.data?.departments;
        const tabledata = depts?.map((data, i) => {
          return [i + 1, data.department_name, data.enabled, data._id];
        });
        setPage(response.data.currentPage);
        setDepartmentData(tabledata);
        setSortBy(response?.data?.sortBy);
        setSortOrder(response?.data?.sortOrder);
        setLoading(false);
      })
      .catch((error) => {
        console.error("API Error ", error);
        setLoading(false);
      });
  };

  const settingNewDepartment = (e) => {
    const value = e.target.value;
    const alphaNumericRegex = /^[a-zA-Z0-9\s]*$/;
    if (e.target.value.length <= 50 && alphaNumericRegex.test(value)) {
      setNewDepartment(e.target.value);
    }
  };

  const settingNewDepartmentEdit = (e) => {
    const value = e.target.value;
    const alphaNumericRegex = /^[a-zA-Z0-9\s]*$/;
    if (e.target.value.length <= 50 && alphaNumericRegex.test(value)) {
      setInlineValue(e.target.value);
    }
  };

  function editDepartment() {
    const trimmedValue = !!inlineValue && inlineValue?.trim();
    if (!!trimmedValue && inlineValue?.trim() !== "") {
      const body = {
        departmentId: inEditingMode[3],
        newDepartment: trimmedValue,
        enabled: inEditingMode[2],
      };
      axiosConfig
        .put(apiRoutes.adminUpdateDepartment, body)
        .then((res) => {
          toast(res?.data?.message, {
            bodyClassName: "sucessToastBody",
            progressClassName: "sucessToastProgress",
            autoClose: 8000,
          });
          getDepartments(page, limit, true);
          setInEditingMode(false);
          setInlineValue(false);
          setInlineValue("");
        })
        .catch((err) => {
          if (err?.response?.status === 409) {
            toast.error("Department name must be unique", {
              bodyClassName: "font-color-black",
              autoClose: 8000,
            });
            setInEditingMode(false);
            setInlineValue("");
          }
        });
    } else {
      toast.error("Please enter department name.", {
        bodyClassName: "font-color-black",
        autoClose: 8000,
      });
      setInlineValue("");
    }
  }

  function enableDisableDepartment(rowData) {
    setTimeout(() => {
      const body = {
        departmentId: rowData[3],
        newDepartment: rowData[1],
        enabled: !rowData[2],
      };
      axiosConfig
        .put(apiRoutes.adminUpdateDepartment, body)
        .then((res) => {
          toast(res?.data?.message, {
            bodyClassName: "sucessToastBody",
            progressClassName: "sucessToastProgress",
            autoClose: 8000,
          });
          getDepartments(page, limit, true);
        })
        .catch((error) => {
          if (error?.response?.status === 400) {
            toast.error(error?.response?.data?.message, {
              bodyClassName: "font-color-black",
              autoClose: 8000,
            });
          }
        });
      setOpen(false);
    }, 500);
  }

  function addNewDepartment() {
    const trimmedDepartment = !!newDepartment && newDepartment.trim();
    if (!!trimmedDepartment && newDepartment.trim() !== "") {
      const body = { department_name: trimmedDepartment };
      axiosConfig
        .post(apiRoutes.adminAddDepartment, body)
        .then(() => {
          getDepartments(true);
          toast("Department added successfully!", {
            bodyClassName: "sucessToastBody",
            progressClassName: "sucessToastProgress",
            autoClose: 8000,
          });
          setNewDepartment("");
        })
        .catch((err) => {
          if (err?.response?.status === 409) {
            toast.error("Department name must be unique", {
              bodyClassName: "font-color-black",
              autoClose: 8000,
            });
            setNewDepartment("");
          }
        });
    } else {
      toast.error("Please enter department name.", {
        bodyClassName: "font-color-black",
        autoClose: 8000,
      });
      setNewDepartment("");
    }
  }

  const columns = [
    {
      name: "#",
      options: {
        filter: false,
        searchable: false,
        sort: false,
      },
      customHeadRender: (columnMeta) => {
        return <th className="rightAlign ">{columnMeta.name}</th>;
      },
      customBodyRender: (columnMeta) => {
        return <td className="rightAlign ">{columnMeta}</td>;
      },
    },
    {
      name: "Department",
      options: {
        filter: true,
        sortThirdClickReset: true,
        customHeadRender: (columnMeta) => {
          return (
            <th className="leftAlign">
              {columnMeta.name}
              {sortOrder === "asc" ? (
                <IconButton
                  onClick={() => {
                    handleSortDescending();
                  }}
                >
                  <ArrowUpwardIcon />
                </IconButton>
              ) : (
                <IconButton
                  onClick={() => {
                    handleSortAscending();
                  }}
                >
                  <ArrowDownwardIcon />
                </IconButton>
              )}
            </th>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <td className="leftAlign">
              {inEditingMode[3] === tableMeta?.rowData[3] ? (
                <input
                  autoFocus
                  type="text"
                  value={inlineValue}
                  // onChange={(e) => setInlineValue(e.target.value)}
                  onChange={settingNewDepartmentEdit}
                  maxLength="50"
                  onKeyDown={(e) => {
                    if (e.code === "Enter" || e.code === "NumpadEnter")
                      editDepartment();
                  }}
                />
              ) : (
                <div>{value}</div>
              )}
            </td>
          );
        },
      },
    },
    {
      name: "Status",
      options: {
        filter: false,
        searchable: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid container className="actionIcons toggleSwitch">
              <Grid item display="flex">
                <FormControlLabel
                  value={value ? true : false}
                  control={
                    <Switch
                      color="green"
                      checked={value}
                      value={value ? true : false}
                      className="grid-action-toggle"
                    />
                  }
                  onChange={() => {
                    setInlineValue(false);
                    setInEditingMode(false);
                    setCurrentRowData(tableMeta?.rowData);
                    if (value) {
                      setOpen(true);
                    } else {
                      enableDisableDepartment(tableMeta?.rowData);
                    }
                  }}
                />
              </Grid>
            </Grid>
          );
        },
      },
    },
    {
      name: "Action",
      options: {
        filter: false,
        searchable: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid container className="actionIcons">
              <Tooltip title="Edit">
                <Grid item display="flex" className="actionIcon">
                  {inEditingMode[3] === tableMeta?.rowData[3] ? (
                    <FaCheck
                      onClick={() => {
                        editDepartment();
                      }}
                      className="gridEditIcon"
                    />
                  ) : (
                    <FaPencilAlt
                      onClick={() => {
                        setInlineValue(tableMeta?.rowData[1]);
                        setInEditingMode(tableMeta?.rowData);
                      }}
                      className="gridEditIcon"
                    />
                  )}
                </Grid>
              </Tooltip>
              <Grid item display="flex">
                {inEditingMode[3] === tableMeta?.rowData[3] && (
                  <Tooltip title="Cancel">
                    <Grid item className="cross-icon">
                      <FaTimes
                        className="gridEditIcon"
                        onClick={() => {
                          setInlineValue(false);
                          setInEditingMode(false);
                        }}
                      />
                    </Grid>
                  </Tooltip>
                )}
              </Grid>
            </Grid>
          );
        },
      },
    },
    {
      name: "id",
      options: {
        display: false,
        filter: false,
        sort: false,
        viewColumns: false,
      },
    },
  ];
  console.log("departmentData", departmentData);
  return (
    <>
      <PageTitle title="Departments" />
      <div className="table-upper">
        <div className="search_table">
          <AiOutlineSearch />
          <input
            id="standard-basic"
            label="Search"
            defaultValue={searchText}
            placeholder="Search"
            onChange={(e) => searchTextData(e.target.value)}
            maxLength={50}
          />
        </div>
        <Grid container spacing={4} className={classes.addNew}>
          <div className={classes.addNewDiv}>
            <TextField
              value={newDepartment}
              size="small"
              className={classes.addNewText}
              variant="outlined"
              onChange={settingNewDepartment}
              onKeyDown={(e) => {
                if (e.code === "Enter" || e.code === "NumpadEnter")
                  addNewDepartment();
              }}
            />
          </div>
          <div className={classes.addNewDiv1}>
            <Button
              className={classes.addNewButton}
              onClick={() => addNewDepartment()}
              variant="outlined"
            >
              Add
            </Button>
          </div>
        </Grid>
      </div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {!loading && (
            <MUIDataTable
              rowsPerPage={-1}
              data={departmentData ? departmentData : []}
              columns={columns}
              className="custom-table-outer"
              options={{
                filterType: "checkbox",
                selectableRows: "none",
                download: false,
                print: false,
                setCellProps: () => ({ style: { maxWidth: "159px" } }),
                setTableProps: () => ({ className: "custom-table-design" }),
                viewColumns: false,
                searchText: searchText,
                search: false,
                filter: false,
                textLabels: {
                  body: {
                    noMatch: "No data found",
                  },
                },
                pagination: false,
              }}
            />
          )}

          <CustomIcons
            totalPages={totalPages}
            setTotalPages={setTotalPages}
            setPage={setPage}
            pageNew={page}
            onRowsPerPageChange={handleRowsPerPageChange}
            handleFetchPageData={handleFetchPageData}
          />
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={false}
        maxWidth={"sm"}
      >
        <div className="popup-main">
          <DialogTitle id="alert-dialog-title" className="popup-title-outer">
            <Box display="flex" alignItems="center">
              <Box flexGrow={1} className="popup-title"></Box>
              <Box className="popup-icon">
                <IconButton onClick={() => setOpen(false)}>
                  <RxCross2 />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              className="popup-text"
            >
              Are you sure you want to disable{" "}
              <b>{currentRowData && currentRowData[1]}</b> department?
            </DialogContentText>
          </DialogContent>
          <DialogActions className={"popup-button-outer"}>
            <Button
              variant="contained"
              className={"btn-design-theme yes-button"}
              onClick={() => enableDisableDepartment(currentRowData)}
            >
              Yes
            </Button>
            <Button
              variant="contained"
              className="btn-design-theme cancel-button"
              onClick={() => setOpen(false)}
            >
              No
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
}
