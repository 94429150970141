import React, { useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle/PageTitle";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@material-ui/core";
import { FaPencilAlt, FaCheck, FaTimes } from "react-icons/fa";
import useStyles from "./styles";
import { apiRoutes } from "../../utils/apiRoutes";
import axiosConfig from "../../utils/axiosConfig";
import MUIDataTable from "mui-datatables";
import { toast } from "react-toastify";
import { AiOutlineSearch } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";
import { CustomIcons } from "../CustomIcons";
import Tooltip from "@material-ui/core/Tooltip";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

export default function Regions() {
  const classes = useStyles();
  const [regionData, setRegionData] = useState();
  const [inEditingMode, setInEditingMode] = useState(false);
  const [inlineValue, setInlineValue] = useState("");
  const [open, setOpen] = useState(false);
  const [newRegion, setNewRegion] = useState("");
  const [currentRowData, setCurrentRowData] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [showReassign, setShowReassign] = useState(false);
  const [selectValue, setSelectValue] = useState(-1);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [sortBy, setSortBy] = useState("REGIONS");
  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {
    getRegions();
  }, []);

  function getRegions(newPage = page, limitN = limit, rep = false) {
    if (!rep) setLoading(true);
    axiosConfig
      .get(apiRoutes.adminGetRegion, {
        params: { page: newPage, limit: limitN },
      })
      .then((response) => {
        const depts = response?.data?.regions;
        const tabledata = depts?.map((data, i) => {
          return [
            response?.data?.limit * (response?.data.currentPage - 1) + i + 1,
            data.regions_name,
            data.enabled,
            data._id,
          ];
        });
        setRegionData(tabledata);
        setPage(response.data.currentPage);
        setTotalPages(response.data.totalPages);
        setLimit(response.data.per_page_record);
        setLoading(false);
      })
      .catch((error) => {});
  }

  const handleFetchPageData = (newpage, newlimit) => {
    getRegions(newpage, limit);
  };
  function editRegion() {
    const trimmedValue = !!inlineValue && inlineValue.trim();
    if (!!trimmedValue && inlineValue.trim() !== "") {
      const body = {
        RegionId: inEditingMode[3],
        newRegion: trimmedValue,
        enabled: inEditingMode[2],
      };
      axiosConfig
        .put(apiRoutes.adminUpdateRegion, body)
        .then((res) => {
          toast(res?.data?.message, {
            bodyClassName: "sucessToastBody",
            progressClassName: "sucessToastProgress",
            autoClose: 8000,
          });
          getRegions(page, limit, true);
          setInEditingMode(false);
          setInlineValue(false);
        })
        .catch((error) => {
          if (error?.response?.status === 409) {
            toast.error("Region name must be unique", {
              bodyClassName: "font-color-black",
              autoClose: 8000,
            });
            setInEditingMode(false);
            setInlineValue("");
          } else {
            toast.error(error.response.data.message, {
              bodyClassName: "font-color-black",
              autoClose: 8000,
            });
          }
        });
    } else {
      toast.error("Please enter region name", {
        bodyClassName: "font-color-black",
        autoClose: 8000,
      });
      setInlineValue("");
    }
  }

  const handleRowsPerPageChange = (newRowsPerPage) => {
    setLimit(+newRowsPerPage);
    getRegions(1, +newRowsPerPage);
    setCurrentPage(1);
  };

  function enableDisableRegion(rowData) {
    setTimeout(() => {
      const body = {
        RegionId: rowData[3],
        newRegion: rowData[1],
        enabled: !rowData[2],
      };
      axiosConfig
        .put(apiRoutes.adminUpdateRegion, body)
        .then((res) => {
          getRegions(true);
          toast(res?.data?.message, {
            bodyClassName: "sucessToastBody",
            progressClassName: "sucessToastProgress",
            autoClose: 8000,
          });
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            bodyClassName: "font-color-black",
            autoClose: 8000,
          });
        });
      setOpen(false);
    }, 500);
  }

  function addNewRegion() {
    const trimmedRegion = !!newRegion && newRegion.trim();
    if (!!trimmedRegion && newRegion.trim() !== "") {
      const body = { regions_name: trimmedRegion };
      axiosConfig
        .post(apiRoutes.adminAddRegion, body)
        .then(() => {
          getRegions(true);
          setNewRegion("");
          toast("Region added sucessfully!", {
            bodyClassName: "sucessToastBody",
            progressClassName: "sucessToastProgress",
            autoClose: 8000,
          });
        })
        .catch((error) => {
          if (error?.response?.status === 409) {
            toast.error("Region name must be unique", {
              bodyClassName: "font-color-black",
              autoClose: 8000,
            });
            setNewRegion("");
          } else {
            toast.error(error.response.data.message, {
              bodyClassName: "font-color-black",
              autoClose: 8000,
            });
          }
        });
    } else {
      toast.error("Please enter region name", {
        bodyClassName: "font-color-black",
        autoClose: 8000,
      });
      setNewRegion("");
    }
  }

  function handleClose() {
    setOpen(false);
    setShowReassign(false);
    setSelectValue(-1);
  }

  function handleUpdateBusinessRegion() {
    const oldRegionId = currentRowData[3];
    if (!selectValue || selectValue === -1 || selectValue === "") {
      toast.error("Please select a region", {
        autoClose: 8000,
        bodyClassName: "font-color-black",
      });
    }
    const body = {
      new_regions_id: selectValue,
      old_regions_id: oldRegionId,
      enabled: false,
    };
    axiosConfig
      .put(apiRoutes.adminUpdateBusinessRegion, body)
      .then((response) => {
        toast("Region reassigned sucessfully!", {
          bodyClassName: "sucessToastBody",
          progressClassName: "sucessToastProgress",
          autoClose: 8000,
        });
        getRegions();
        handleClose();
      })
      .catch((error) => {
        handleClose(error.response.data.message);
        toast.error(error.response.data.message, {
          bodyClassName: "font-color-black",
          autoClose: 8000,
        });
      });
    getRegions(true);
  }

  function searchTextData(e) {
    const regions = e;
    axiosConfig
      .get(apiRoutes.adminGetRegion, {
        params: { search: regions != "" ? regions : null, limit: limit },
      })
      .then((response) => {
        const depts = response?.data?.regions;

        const tabledata = depts?.map((data, i) => {
          return [i + 1, data.regions_name, data.enabled, data._id];
        });

        setRegionData(tabledata);

        setLoading(false);
      })
      .catch((error) => {
        console.error("API Error:", error);
        setLoading(false);
      });
  }
  const handleSortAscending = () => {
    axiosConfig
      .get(apiRoutes.adminGetRegion, {
        params: { sortBy: "regions_name", sortOrder: "asc" },
      })
      .then((response) => {
        const depts = response?.data?.regions;
        const tabledata = depts?.map((data, i) => {
          return [i + 1, data.regions_name, data.enabled, data._id];
        });
        setRegionData(tabledata);
        setPage(response.data.currentPage);

        setSortBy(response?.data?.sortBy);
        setSortOrder(response?.data?.sortOrder);

        setLoading(false);
      })
      .catch((error) => {
        console.error("API Error:", error);
        setLoading(false);
      });
  };

  const handleSortDescending = () => {
    axiosConfig
      .get(apiRoutes.adminGetRegion, {
        params: { sortBy: "regions_name", sortOrder: "desc" },
      })
      .then((response) => {
        const depts = response?.data?.regions;
        const tabledata = depts?.map((data, i) => {
          return [i + 1, data.regions_name, data.enabled, data._id];
        });
        setRegionData(tabledata);
        setPage(response.data.currentPage);

        setSortBy(response?.data?.sortBy);
        setSortOrder(response?.data?.sortOrder);

        setLoading(false);
      })
      .catch((error) => {
        console.error("API Error:", error);
        setLoading(false);
      });
  };

  const columns = [
    {
      name: "#",
      options: {
        filter: false,
        searchable: false,
        sort: false,
      },
      customHeadRender: (columnMeta) => {
        return <th className="rightAlign">{columnMeta.name}</th>;
      },
      customBodyRender: (columnMeta) => {
        return <td className="rightAlign">{columnMeta}</td>;
      },
    },
    {
      name: "Region",
      options: {
        filter: true,
        sortThirdClickReset: true,
        customHeadRender: (columnMeta) => {
          return (
            <th className="leftAlign">
              {columnMeta.name}
              {sortOrder === "asc" ? (
                <IconButton
                  onClick={() => {
                    handleSortDescending();
                  }}
                >
                  <ArrowUpwardIcon />
                </IconButton>
              ) : (
                <IconButton
                  onClick={() => {
                    handleSortAscending();
                  }}
                >
                  <ArrowDownwardIcon />
                </IconButton>
              )}
            </th>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <td className="leftAlign">
              {inEditingMode[3] === tableMeta?.rowData[3] ? (
                <input
                  autoFocus
                  type="text"
                  value={inlineValue}
                  onChange={settingNewRegionEdit}
                  // onChange={(e) => setInlineValue(e.target.value)}
                  maxLength="50"
                  onKeyDown={(e) => {
                    if (e.code === "Enter" || e.code === "NumpadEnter")
                      editRegion();
                  }}
                />
              ) : (
                <div>{value}</div>
              )}
            </td>
          );
        },
      },
    },
    {
      name: "Status",
      options: {
        filter: false,
        searchable: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid container className="actionIcons toggleSwitch">
              <Grid item display="flex">
                <FormControlLabel
                  value={value ? true : false}
                  control={
                    <Switch
                      color="green"
                      checked={value}
                      value={value ? true : false}
                      className="grid-action-toggle"
                    />
                  }
                  onChange={() => {
                    setInlineValue(false);
                    setInEditingMode(false);
                    setCurrentRowData(tableMeta?.rowData);
                    if (value) {
                      setOpen(true);
                    } else {
                      enableDisableRegion(tableMeta?.rowData);
                    }
                  }}
                />
              </Grid>
            </Grid>
          );
        },
      },
    },
    {
      name: "Action",
      options: {
        filter: false,
        searchable: false,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid container className="actionIcons">
              {tableMeta?.rowData[2] && (
                <Tooltip title="Edit">
                  <Grid item display="flex" className="pencil-icon">
                    {inEditingMode[3] === tableMeta?.rowData[3] ? (
                      <FaCheck
                        className="gridEditIcon"
                        onClick={() => {
                          editRegion();
                        }}
                      />
                    ) : (
                      <FaPencilAlt
                        className="gridEditIcon"
                        onClick={() => {
                          setInlineValue(tableMeta?.rowData[1]);
                          setInEditingMode(tableMeta?.rowData);
                        }}
                      />
                    )}
                  </Grid>
                </Tooltip>
              )}
              <Grid item display="flex">
                {inEditingMode[3] === tableMeta?.rowData[3] && (
                  <Tooltip title="Cancel">
                    <Grid item className="cross-icon">
                      <FaTimes
                        className="gridEditIcon"
                        onClick={() => {
                          setInlineValue(false);
                          setInEditingMode(false);
                        }}
                      />
                    </Grid>
                  </Tooltip>
                )}
              </Grid>
            </Grid>
          );
        },
      },
    },
    {
      name: "id",
      options: {
        display: false,
        filter: false,
        sort: false,
        viewColumns: false,
      },
    },
  ];

  const settingNewRegion = (e) => {
    const value = e.target.value;
    const alphabeticRegex = /^[a-zA-Z\s]*$/;

    if (e.target.value.length <= 50 && alphabeticRegex.test(value)) {
      setNewRegion(e.target.value);
    }
  };

  const settingNewRegionEdit = (e) => {
    const value = e.target.value;
    const alphabeticRegex = /^[a-zA-Z\s]*$/;

    if (e.target.value.length <= 50 && alphabeticRegex.test(value)) {
      setInlineValue(e.target.value);
    }
  };

  return (
    <>
      <PageTitle title="Regions" />
      <div className="table-upper">
        <div className="search_table">
          <AiOutlineSearch />
          <input
            id="standard-basic"
            label="Search"
            defaultValue={searchText}
            placeholder="Search"
            onChange={(e) => searchTextData(e.target.value)}
            maxLength={50}
          />
        </div>
        <Grid container spacing={4} className={classes.addNew}>
          <div className={classes.addNewDiv}>
            <TextField
              value={newRegion}
              size="small"
              className={classes.addNewText}
              variant="outlined"
              onChange={settingNewRegion}
              onKeyDown={(e) => {
                if (e.code === "Enter" || e.code === "NumpadEnter")
                  addNewRegion();
              }}
            />
          </div>
          <div className={classes.addNewDiv1}>
            <Button
              className={classes.addNewButton}
              onClick={() => addNewRegion()}
              variant="outlined"
            >
              Add
            </Button>
          </div>
        </Grid>
      </div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {!loading && (
            <MUIDataTable
              rowsPerPage={-1}
              data={regionData ? regionData : []}
              columns={columns}
              className="custom-table-outer"
              options={{
                filterType: "checkbox",
                download: false,
                print: false,
                pagination: false,
                filter: false,
                selectableRows: "none",
                viewColumns: false,
                setTableProps: () => ({ className: "custom-table-design" }),
                searchText: searchText,
                search: false,
                textLabels: {
                  body: {
                    noMatch: "No data found",
                  },
                },
                setCellProps: () => ({ style: { maxWidth: "159px" } }),
              }}
            />
          )}
        </Grid>
      </Grid>

      <CustomIcons
        totalPages={totalPages}
        setTotalPages={setTotalPages}
        setPage={setPage}
        pageNew={page}
        onRowsPerPageChange={handleRowsPerPageChange}
        handleFetchPageData={handleFetchPageData}
      />
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"sm"}
      >
        <div className="popup-main">
          <DialogTitle id="alert-dialog-title" className="popup-title-outer">
            <Box display="flex" alignItems="center">
              <Box flexGrow={1} className="popup-title">
                {" "}
                {"Reassign Region"}
              </Box>
              <Box className="popup-icon">
                <IconButton onClick={handleClose}>
                  <RxCross2 />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              className="popup-text"
            >
              Would you like to reassign associated business units?
            </DialogContentText>
          </DialogContent>
          <DialogActions className={"popup-button-outer"}>
            <Button
              variant="contained"
              className={
                showReassign
                  ? "btn-design-theme yes-button"
                  : "btn-design-theme"
              }
              onClick={() => setShowReassign(true)}
            >
              Yes
            </Button>
            <Button
              variant="contained"
              className="btn-design-theme cancel-button"
              onClick={() => enableDisableRegion(currentRowData)}
            >
              No
            </Button>
          </DialogActions>
          {showReassign && (
            <div className={classes.reassignDiv}>
              <DialogContent className="userRoleText">
                <DialogContentText
                  id="alert-dialog-description"
                  className="popup-text"
                >
                  Please select a new region from the options below.
                </DialogContentText>
                <div className={classes.flexCenter}>
                  Region &nbsp;
                  <FormControl
                    variant="outlined"
                    className={classes.modalSelect}
                    size="small"
                  >
                    <select
                      className="permission-role-select"
                      value={selectValue}
                      onChange={(i) => {
                        setSelectValue(i?.target.value);
                      }}
                      MenuProps={{ classes: { paper: classes.menuPaper } }}
                    >
                      <option value={-1} className="select-none">
                        Select
                      </option>
                      {regionData?.map((data, i) => {
                        if (data[2] && data[3] !== currentRowData[3]) {
                          return (
                            <option key={i} value={data[3]}>
                              {data[1]}
                            </option>
                          );
                        }
                        return null;
                      })}
                    </select>
                    <div class="selectArrow">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 24 24"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M12 16L6 10H18L12 16Z"></path>
                      </svg>
                    </div>
                  </FormControl>
                </div>
              </DialogContent>
              <DialogActions className={"popup-button-outer"}>
                <Button
                  variant="contained"
                  className={"btn-design-theme"}
                  onClick={handleUpdateBusinessRegion}
                >
                  Save
                </Button>
                <Button
                  variant="contained"
                  className={"btn-design-theme cancel-button"}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </DialogActions>
            </div>
          )}
        </div>
      </Dialog>
    </>
  );
}
