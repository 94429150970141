export const routeConfigs = {
    admin: "/admin",
    adminLogin: "/admin-login",
    adminForgotpassword: "/admin-forgotpassword",
    adminResetpassword: "/admin-resetpassword",
    adminDashboard: "/admin/dashboard",
    //menu routes
    adminUserRoles: "/admin/user-roles/view",
    adminUserRolesPermissions: "/admin/user-roles/permissions",
    adminUserRequests: "/admin/users/requests",
    adminUserAll: "/admin/users/all",
    adminAssetCategory: "/admin/asset/category",
    adminAssetSubcategory: "/admin/asset/subcategory",
    adminAssetType: "/admin/asset/type",
    adminAssetsAdd: "/admin/assets/add",
    adminAssetsAll: "/admin/assets/all",
    adminAssetsCollections: "/admin/assets/collections",
    adminAuiIptcCorefields: "/admin/aui/iptc-core-fields",
    adminAuiAdditionalInfo: "/admin/aui/additional-info",
    adminDepartments: "/admin/departments",
    adminRegions: "/admin/regions",
    adminBusinessunits: "/admin/business-units",
    adminBanners: "/admin/banners",
    adminPagesAll: "/admin/pages/all",
    adminPagesManage: "/admin/pages/manage",
    adminCmschangesFeaturedassets: "/admin/cms-changes/featured-assets",
    adminCmschangesCuratedgallery: "/admin/cms-changes/curated-gallery",
    adminCmschangesSearchbyfilters: "/admin/cms-changes/search-by-filters",
    //profile-pages
    adminProfileSettings: "/admin/profile/settings",
    adminChangePassword: "/admin/change-password",
    //user routes
    userRegister: "/register",
    userLogin: "/login",
    userThankyouPage: "/thankyou",
    userForgotPassword: "/forgotpassword",
    userResetPassword: "/resetpassword",
    //homepage
    userHome: "/homepage"
}