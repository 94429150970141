import React from "react";
import { Link } from "react-router-dom";
import { routeConfigs } from "../../utils/routeConfig";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useStyles from "./styles";

const useStyless = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  margin: {
    marginTop: "16px",
  },
}));

function Thankyou() {
  const classes = useStyles();
  const classs = useStyless();
  return (
    <Grid container className={`${classes.container} thankyou-main-class`}>
      <div className="outer-container">
        <img
          src="./images/register3.png"
          alt="logo"
          className={classes.logotypeContainer}
        />
        <div className="user-login-main">
           <div className="image_text">
            <img
              src="./logos/full-stack-color.svg"
              alt="logo"
              className={classes.logotypeImage}
            />
            <div className="user-login-container-left thanks-inner">
                  <div className="thanks-empty">
                    <h1>Manage Your Project Assets</h1>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                      ullamco laboris nisi ut aliquip ex ea commodo consequat.
                      Duis aute irure dolor
                    </p>
                    <h3>Working Hours</h3>
                    <p>Monday to Friday | 00:00 am to 00:00 pm</p>
                    <h3>Call Us</h3>
                    <p>+1 0000000000</p>
                  </div>
                </div>
            </div>
          <div className="user-login-bg">
            <div className="user-login-middle thankyou-page-middle">
              <div className="user-login-container">
                
                <div className="user-login-container-right">
                  <div className="user-login-text-center">
                    <span>Thank you for Registering to TCDAM!!!</span>
                    <span>
                      You will be notified about your account approval shortly
                      in an email by our admin.
                    </span>
                  </div>
                  <div className="user-login-text-bottom">
                    <Link to={routeConfigs.userLogin}>Login Here</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="user-login-footer">
            <ul>
              <li>© 1902-2023 TCDAM, LLC. All rights reserved</li>
            </ul>
          </div>
        </div>
      </div>
    </Grid>
  );
}

export default Thankyou;
