import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  pageTitleDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  pageTitleButton: {
    height: "max-content",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  margin: {
    marginTop: "16px",
  },
  modalSelect: {
    marginLeft: "16px",
    minWidth: "160px",
    background: " #fff",
    borderRadius: "4px",
  },
  hilightedYesButton: {
    backgroundColor: "rgba(25, 118, 210, 0.04)",
  },
  flexCenter: {
    margin: "40px 0 10px 0",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#0a0a0a",
    fontSize: "17px",
  },
  menuPaper: {
    marginTop: "52px",
    maxHeight: "300px",
    top: "554px !important",
  },
  addNew: {
    marginBottom: "16px",
    justifyContent: "end",
    paddingRight: "20px",
    cursor: "pointer",
    "& button": {
      background: "#0056B8",
      color: "#fff",
      width: " 100%",
      height: "100%",
      borderRadius: "0 5px 5px 0",
      fontWeight: "600",
      transition: "all 0.4s",
      textTransform: "capitalize",
      "&:hover": {
        background: "transparent",
        color: "#0056B8",
        width: " 100%",
        height: "100%",
        border: "1px solid #0056B8",
        borderRadius: "0 5px 5px 0",
        fontWeight: "600",
      },
    },
  },
  addNewText: {
    "& fieldset": {
      borderRight: "0",
      width: "100%",
      borderRadius: "5px 0 0 5px",
    },
  },
  addNewButton: {},
  addNewDiv: {
    alignItems: "center",
    display: "flex",
    paddingLeft: "18px",
  },
  addNewDiv1: {
    maxWidth: "120px",
    border: 0,
  },
}));
