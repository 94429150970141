import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  TextField,
  Fade,
  Modal,
  Backdrop,
  InputAdornment,
  IconButton
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { Link, withRouter } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useHistory } from "react-router-dom";

import useStyles from "./styles";
import { useUserHomeDispatch, loginUserHome, signOut } from "../../context/UserHomeContext";
import { recaptcha_site_key } from "../../utils/envConst";
import { routeConfigs } from "../../utils/routeConfig";

const useStyless = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  margin: {
    marginTop: '16px',
  }
}));

function UserLogin(props) {
  const classes = useStyles();
  const classs = useStyless();
  const history = useHistory();
  const userDispatch = useUserHomeDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const [open, setOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const captchaRef = useRef(null);
  const clearTimerRef = useRef();

  useEffect(() => {
    if (errorMessage === "409") {
      setOpen(true);
    }
  }, [errorMessage])

  const handleLogin = (e) => {
    e.preventDefault();
    clearTimeout(clearTimerRef.current);
    const captchaToken = captchaRef.current.getValue();
    loginUserHome(
      userDispatch,
      emailValue,
      passwordValue,
      props.history,
      setIsLoading,
      setError,
      setErrorMessage,
      captchaToken
    )
    clearTimerRef.current = setTimeout(() => {
      setError(false);
      setErrorMessage("");
    }, 13420)
  }

  const handleEmailChange = (e) => {
    if (e.target.value.length <= 300) {
      setEmailValue(e.target.value);
    }
    setError(false);
    setErrorMessage("");
  }

  const handlePasswordChange = (e) => {
    if (e.target.value.length <= 20) {
      setPasswordValue(e.target.value);
    }
    setError(false);
    setErrorMessage("");
  }

  const handleCaptchaChange = (e) => {
    setError(false);
    setErrorMessage("");
  }

  return (
    <Grid container className={`${classes.container} userLogin-main-class`}>
      <div className="outer-container">
        <img
          src="./images/register3.png"
          alt="logo"
          className={classes.logotypeContainer}
        />
        <div className="user-login-main">
          <div className="image_text">
            <img
              src="./logos/full-stack-color.svg"
              alt="logo"
              className={classes.logotypeImage}
            />
            <div className="user-login-container-left">
              <h1>Join TCDAM Today!</h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
              </p>
              <h3>Working Hours</h3>
              <p>Monday to Friday | 00:00 am to 00:00 pm</p>
              <h3>Call Us</h3>
              <p>+1 0000000000</p>
            </div>
          </div>
          <div className="user-login-bg">
            <div className="user-login-middle">
              <div className="user-login-container">
                <div className="user-login-container-right">
                  <div className="user-login-container-right-heading-text">
                    <h2>Login</h2>
                  </div>
                  <Fade in={error}>
                    <Typography color="secondary" className={`${classes.marginTop} ${classes.errorMessage} `}>
                      {errorMessage !== "409" && errorMessage}
                    </Typography>
                  </Fade>
                  <form onSubmit={handleLogin}>
                    <React.Fragment>
                      <TextField
                        id="email"
                        InputProps={{
                          classes: {
                            underline: classes.textFieldUnderline,
                            input: classes.textField,
                          },
                        }}
                        value={emailValue}
                        onChange={handleEmailChange}
                        margin="normal"
                        placeholder="Email"
                        type="text"
                        fullWidth
                        autoComplete="on"
                        maxLength="300"
                      />
                      <TextField
                        id="password"
                        InputProps={{
                          classes: {
                            underline: classes.textFieldUnderline,
                            input: classes.textField,
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                        value={passwordValue}
                        onChange={handlePasswordChange}
                        margin="normal"
                        placeholder="Password"
                        type={showPassword ? "text" : "password"}
                        fullWidth
                        autoComplete="on"
                        maxLength="10"
                      />
                      <ReCAPTCHA
                        className={classes.loginRecaptcha}
                        ref={captchaRef}
                        sitekey={recaptcha_site_key}
                        onChange={handleCaptchaChange}
                      />
                      <div className={classes.formButtons}>
                        {isLoading ? (
                          <CircularProgress size={26} className={classes.loginLoader} />
                        ) : (
                          <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            type="submit"
                          >
                            Sign In
                          </Button>
                        )}
                        <Button
                          color="primary"
                          size="large"
                          className={classes.forgetButton}
                          onClick={() => history.push(routeConfigs.userForgotPassword)}
                        >
                          Forgot Password
                        </Button>
                      </div>
                    </React.Fragment>
                  </form>
                  <div className="notMember">Not a member? <Link to={routeConfigs.userRegister}>Register Here</Link></div>
                  <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classs.modal}
                    open={open}
                    onClose={() => { setOpen(false) }}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                      timeout: 500,
                    }}
                  >
                    <Fade in={open}>
                      <div className={classs.paper}>
                        <Typography className={classes.text} size="md">
                          You have already signed in on another system.
                        </Typography>
                        <Typography className={classes.text} size="md">
                          Please sign out from that device to login here.
                        </Typography>
                        <Button
                          variant="contained"
                          color="primary"
                          size="large"
                          type="button"
                          onClick={() => {
                            signOut(userDispatch, props.history, emailValue);
                            setOpen(false);
                          }}
                          className={classs.margin}
                        >
                          Sign Out of All Devices
                        </Button>
                      </div>
                    </Fade>
                  </Modal>
                </div>
              </div>
            </div>
            <div className="copyright_name">
              <Typography color="primary" className={classes.copyright}>
                © 1902-{new Date().getFullYear()}{" "}
                <a
                  style={{ textDecoration: "none", color: "inherit" }}
                  href="http://tcdam.csdevhub.com/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  TCDAM
                </a>
                , LLC. All rights reserved.
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </Grid>
  );
}

export default withRouter(UserLogin);
