import React from "react";
import axios from "axios";
import { routeConfigs } from "../utils/routeConfig";
import {
  removeDataFromLocalStorage,
  retrieveDataFromLocalStorage,
  saveDataInLocalStorage,
  localConfig
} from "../utils/localStorage";
import { apiRoutes } from "../utils/apiRoutes";
import { checkValidEmail } from "../utils/validationUtils";
import axiosConfig from "../utils/axiosConfig";

const UserHomeStateContext = React.createContext();
const UserHomeDispatchContext = React.createContext();

function userHomeReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserHomeProvider({ children }) {
  const [state, dispatch] = React.useReducer(userHomeReducer, {
    isAuthenticated: !!retrieveDataFromLocalStorage(localConfig.userLoginToken),
  });

  return (
    <UserHomeStateContext.Provider value={state}>
      <UserHomeDispatchContext.Provider value={dispatch}>
        {children}
      </UserHomeDispatchContext.Provider>
    </UserHomeStateContext.Provider>
  );
}

function useUserHomeState() {
  const context = React.useContext(UserHomeStateContext);
  if (context === undefined) {
    throw new Error("useUserHomeState must be used within a UserHomeProvider");
  }
  return context;
}

function useUserHomeDispatch() {
  const context = React.useContext(UserHomeDispatchContext);
  if (context === undefined) {
    throw new Error("useUserHomeDispatch must be used within a UserHomeProvider");
  }
  return context;
}

export { UserHomeProvider, useUserHomeState, useUserHomeDispatch, loginUserHome, signOut };

// ###########################################################

async function loginUserHome(
  dispatch,
  email,
  password,
  history,
  setIsLoading,
  setError,
  setErrorMessage,
  captchaToken
) {
  setError(false);
  setIsLoading(true);
  setErrorMessage("");
  const isValidEmail = checkValidEmail(email);

  if (!email) {
    setError(true)
    setErrorMessage("Please enter the mandatory email address");
    setIsLoading(false);
  }
  else if (!isValidEmail) {
    setError(true)
    setErrorMessage("Please enter the valid email address");
    setIsLoading(false);
  }
  else if (!password) {
    setError(true)
    setErrorMessage("Please enter the mandatory password field");
    setIsLoading(false);
  }
  else if (!captchaToken) {
    setError(true)
    setErrorMessage("CAPTCHA is mandatory");
    setIsLoading(false);
  }
  else if (!!email && !!password) {
    setTimeout(() => {
      axios.post(apiRoutes.adminLogin, { email, password, admin_login: false })
        .then((response) => {
          saveDataInLocalStorage(localConfig.userLoginToken, response?.data?.data?.token);
          saveDataInLocalStorage(localConfig.userEmail, response?.data?.data?.email);
          setError(null);
          dispatch({ type: 'LOGIN_SUCCESS' });
          history.push({ pathname: routeConfigs.userHome });
        })
        .catch((e) => {
          const errMessage = e?.response?.data?.message
          if (e?.response?.status === 409) {
            setErrorMessage("409")
            saveDataInLocalStorage(localConfig.userLoginToken, e.response.data.token);
          }
          else if (errMessage) {
            setError(true);
            setErrorMessage(errMessage)
          }
          setIsLoading(false);
        });
    }, 2000)
  } else {
    dispatch({ type: "LOGIN_FAILURE" });
    setError(true);
    setIsLoading(false);
  }
}

async function signOut(dispatch, history, email = "") {
  let localEmail = retrieveDataFromLocalStorage(localConfig.userEmail);
  removeDataFromLocalStorage(localConfig.userEmail);
  if (email !== "") {
    localEmail = email
  }
  if (localEmail && localEmail !== "") {
    await axiosConfig.post(apiRoutes.adminLogout, { email: localEmail })
      .catch(err => { });
  }
  removeDataFromLocalStorage(localConfig.userLoginToken);
  dispatch({ type: "SIGN_OUT_SUCCESS" });
  history.push({ pathname: routeConfigs.userLogin });
}
