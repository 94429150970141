import React from "react";
import PageTitle from "../../components/PageTitle/PageTitle";

export default function ProfileSettings() {
    return (
        <>
            <PageTitle title="Profile Settings" />
        </>
    );
}
