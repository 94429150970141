import React from "react";
import PageTitle from "../../components/PageTitle/PageTitle";

export default function SearchByFilters() {
    return (
        <>
            <PageTitle title="Search By Filters" />
        </>
    );
}
