import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  Fade,
  Modal,
  Backdrop,
  InputAdornment,
  IconButton
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useHistory } from "react-router-dom";

import useStyles from "./styles";
import { useUserDispatch, loginUser, signOut } from "../../context/UserContext";
import { recaptcha_site_key } from "../../utils/envConst";
import { routeConfigs } from "../../utils/routeConfig";

const useStyless = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  margin: {
    marginTop: '16px',
  }
}));

function Login(props) {
  const classes = useStyles();
  const classs = useStyless();
  const history = useHistory();
  const userDispatch = useUserDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const [open, setOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const captchaRef = useRef(null);
  const clearTimerRef = useRef();

  useEffect(() => {
    if (errorMessage === "409") {
      setOpen(true);
    }
  }, [errorMessage])

  const handleLogin = (e) => {
    e.preventDefault();
    clearTimeout(clearTimerRef.current);
    const captchaToken = captchaRef.current.getValue();
    loginUser(
      userDispatch,
      emailValue,
      passwordValue,
      props.history,
      setIsLoading,
      setError,
      setErrorMessage,
      captchaToken
    )
    clearTimerRef.current = setTimeout(() => {
      setError(false);
      setErrorMessage("");
    }, 13420)
  }

  const handleEmailChange = (e) => {
    if (e.target.value.length <= 300) {
      setEmailValue(e.target.value);
    }
    setError(false);
    setErrorMessage("");
  }

  const handlePasswordChange = (e) => {
    if (e.target.value.length <= 20) {
      setPasswordValue(e.target.value);
    }
    setError(false);
    setErrorMessage("");
  }

  const handleCaptchaChange = (e) => {
    setError(false);
    setErrorMessage("");
  }

  return (
    <Grid container className={classes.container}>
      <img src="./images/loginBackground2.jpg" alt="logo" className={classes.logotypeContainer} />
      <div className={classes.formContainer}>
        <img src="./logos/full-stack-color.svg" alt="logo" className={classes.logotypeImage} />
        <div className={classes.form}>
          <Tabs
            value={0}
            indicatorColor="primary"
            textColor="primary"
            centered
          >
            <Tab label="Login" classes={{ root: classes.tab }} />
          </Tabs>

          <form onSubmit={handleLogin}>
            <React.Fragment>
              <Fade in={error}>
                <Typography color="secondary" className={`${classes.marginTop} ${classes.errorMessage} `}>
                  {errorMessage !== "409" && errorMessage}
                </Typography>
              </Fade>
              <TextField
                id="email"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={emailValue}
                onChange={handleEmailChange}
                margin="normal"
                placeholder="Email"
                type="text"
                fullWidth
                autoComplete="on"
                maxLength="300"
              />
              <TextField
                id="password"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                value={passwordValue}
                onChange={handlePasswordChange}
                margin="normal"
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                fullWidth
                autoComplete="on"
                maxLength="10"
              />
              <ReCAPTCHA
                className={classes.loginRecaptcha}
                ref={captchaRef}
                sitekey={recaptcha_site_key}
                onChange={handleCaptchaChange}
              />
              <div className={classes.formButtons}>
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    type="submit"
                  >
                    Sign In
                  </Button>
                )}
                <Button
                  color="primary"
                  size="large"
                  className={classes.forgetButton}
                  onClick={() => history.push(routeConfigs.adminForgotpassword)}
                >
                  Forgot Password
                </Button>
              </div>
            </React.Fragment>
          </form>

          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classs.modal}
            open={open}
            onClose={() => { setOpen(false) }}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={open}>
              <div className={classs.paper}>
                <Typography className={classes.text} size="md">
                  You have already signed in on another system.
                </Typography>
                <Typography className={classes.text} size="md">
                  Please sign out from that device to login here.
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  type="button"
                  onClick={() => {
                    signOut(userDispatch, props.history, emailValue);
                    setOpen(false);
                  }}
                  className={classs.margin}
                >
                  Sign Out of All Devices
                </Button>
              </div>
            </Fade>
          </Modal>
        </div>
        <Typography color="primary" className={classes.copyright}>
          © 1902-{new Date().getFullYear()} <a style={{ textDecoration: 'none', color: 'inherit' }} href="http://tcdam.csdevhub.com/" rel="noopener noreferrer" target="_blank">TCDAM</a>, LLC. All rights reserved.
        </Typography>
      </div>
    </Grid>
  );
}

export default withRouter(Login);
