import React from "react";
import PageTitle from "../../components/PageTitle/PageTitle";

export default function Banners() {
    return (
        <>
            <PageTitle title="Banners" />
        </>
    );
}
