import React, { useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle/PageTitle";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@material-ui/core";
import { FaPencilAlt, FaCheck, FaTimes } from "react-icons/fa";
import useStyles from "./styles";
import { apiRoutes } from "../../utils/apiRoutes";
import axiosConfig from "../../utils/axiosConfig";
import MUIDataTable from "mui-datatables";
import { ToastContainer, toast } from "react-toastify";
import { AiOutlineSearch } from "react-icons/ai";
import { CustomIcons } from "../CustomIcons";
import Tooltip from "@material-ui/core/Tooltip";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";
import IconButton from "@mui/material/IconButton";
import { RxCross2 } from "react-icons/rx";

export default function Business() {
  const classes = useStyles();
  const [businessData, setBusinessData] = useState();
  const [inEditingMode, setInEditingMode] = useState(false);
  const [inlineValue, setInlineValue] = useState("");
  const [open, setOpen] = useState(false);
  const [newBusiness, setNewBusiness] = useState("");
  const [regionData, setRegionData] = useState([]);
  const [selectValue, setSelectValue] = useState("-1");
  const [searchText, setSearchText] = useState("");
  const [currentRowData, setCurrentRowData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [sortBy, setSortBy] = useState("Business Unit");
  const [sortBy1, setSortBy1] = useState("Region");
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortOrder1, setSortOrder1] = useState("asc");

  useEffect(() => {
    getRegion();
  }, [page, limit]);

  useEffect(() => {
    getBusiness();
  }, []);
  function getRegion() {
    axiosConfig
      .get(apiRoutes.adminGetRegion)
      .then((response) => {
        setRegionData(response?.data?.regions);
      })
      .catch((error) => {});
  }

  function getBusiness(newPage = page, limitN = limit, rep = false) {
    if (!rep) setLoading(true);
    axiosConfig
      .get(apiRoutes.adminGetBusiness, {
        params: { page: newPage, limit: limitN },
      })
      .then((response) => {
        const depts = response?.data?.data;
        const tabledata = depts?.map((data, i) => {
          return [
            response?.data?.limit * (response?.data.currentPage - 1) + i + 1,
            data.business_name,
            data?.regions?.regions_name
              ? data?.regions?.regions_name
              : "Unassigned",
            data.enabled,
            data._id,
            data.regions_id,
          ];
        });
        setBusinessData(tabledata);
        setPage(response?.data?.currentPage);
        setTotalPages(response?.data?.totalPages);
        setLimit(response?.data?.per_page_record);
        setLoading(false);
      })
      .catch((error) => {});
  }

  function editBusiness() {
    const trimmedBusiness = !!inlineValue && inlineValue.trim();
    if (!!inlineValue && inlineValue.trim() !== "") {
      const body = {
        businessId: inEditingMode[4],
        newBusiness: trimmedBusiness,
        newRegionsId: inEditingMode[5],
        enabled: inEditingMode[3],
      };
      axiosConfig
        .put(apiRoutes.adminUpdateBusiness, body)
        .then((res) => {
          getBusiness(page, limit, true);
          setInEditingMode(false);
          setInlineValue("");
          toast(res?.data?.message, {
            bodyClassName: "sucessToastBody",
            progressClassName: "sucessToastProgress",
            autoClose: 8000,
          });
        })
        .catch((error) => {
          if (error?.response?.status === 409) {
            toast.error("Business Unit name must be unique", {
              bodyClassName: "font-color-black",
              autoClose: 8000,
            });
            setInEditingMode(false);
            setInlineValue("");
          }
        });
    } else {
      toast.error("Please enter business unit name.", {
        bodyClassName: "font-color-black",
        autoClose: 8000,
      });
      setInlineValue("");
    }
  }

  function addNewBusiness() {
    const trimmedValue = !!newBusiness && newBusiness.trim();
    if (!selectValue || selectValue === "-1" || selectValue === "") {
      toast.error("Please select a region", {
        bodyClassName: "font-color-black",
        autoClose: 8000,
      });
    } else if (!!trimmedValue && newBusiness.trim() !== "") {
      const body = { business_name: trimmedValue, regions_id: selectValue };
      axiosConfig
        .post(apiRoutes.adminAddBusiness, body)
        .then((res) => {
          setNewBusiness("");
          getBusiness();
          setSelectValue("-1");
          toast(res?.data?.message, {
            bodyClassName: "sucessToastBody",
            progressClassName: "sucessToastProgress",
            autoClose: 8000,
          });
        })
        .catch((error) => {
          if (error?.response?.status === 409) {
            toast.error("Department name must be unique", {
              bodyClassName: "font-color-black",
              autoClose: 8000,
            });
            setSelectValue("-1");
            setNewBusiness("");
          }
        });
    } else {
      toast.error("Please enter business unit name.", {
        bodyClassName: "font-color-black",
        autoClose: 8000,
      });
    }
  }
  const handleRowsPerPageChange = (newRowsPerPage) => {
    getBusiness(1, +newRowsPerPage);
    setPage(1);
    setLimit(+newRowsPerPage);
    setCurrentPage(1);
  };

  const settingNewBusiness = (e) => {
    const value = e.target.value;
    const alphabeticRegex = /^[a-zA-Z\s]*$/;
    console.log("e.target.value.length", e.target.value.length);

    if (e.target.value.length <= 50 && alphabeticRegex.test(value)) {
      setNewBusiness(e.target.value);
    }
  };

  const settingNewBusinessEdit = (e) => {
    const value = e.target.value;
    const alphabeticRegex = /^[a-zA-Z\s]*$/;
    if (e.target.value.length <= 50 && alphabeticRegex.test(value)) {
      setInlineValue(e.target.value);
    }
  };

  function enableDisableBusiness(rowData) {
    setTimeout(() => {
      const body = {
        businessId: rowData[4],
        newBusiness: rowData[1],
        newRegionsId: rowData[5],
        enabled: !rowData[3],
      };
      axiosConfig
        .put(apiRoutes.adminUpdateBusiness, body)
        .then((res) => {
          toast(res?.data?.message, {
            bodyClassName: "sucessToastBody",
            progressClassName: "sucessToastProgress",
            autoClose: 8000,
          });
          getBusiness(true);
        })
        .catch((error) => {
          if (error?.response?.status === 400) {
            toast.error(error?.response?.data?.message, {
              bodyClassName: "font-color-black",
              autoClose: 8000,
            });
          }
        });
      setOpen(false);
    }, 500);
  }

  function searchTextData(e) {
    let business_name = e;
    axiosConfig
      .get(apiRoutes.adminGetBusiness, {
        params: {
          search: business_name != "" ? business_name : null,
          limit: limit,
        },
      })
      .then((response) => {
        const depts = response?.data?.data;

        const tabledata = depts?.map((data, i) => {
          return [
            i + 1,
            data?.business_name,
            data?.regions?.regions_name,
            data?.enabled,
            data?._id,
            data?.regions_id,
          ];
        });

        setBusinessData(tabledata);

        setLoading(false);
      })
      .catch((error) => {
        console.error("API Error:", error);
        setLoading(false);
      });
  }
  const handleSortAscending = (column_name) => {
    column_name === "business_name"
      ? axiosConfig
          .get(apiRoutes?.adminGetBusiness, {
            params: { sortBy: column_name, sortOrder: "asc" },
          })
          .then((response) => {
            const depts = response?.data?.data;
            const tabledata = depts?.map((data, i) => {
              return [
                i + 1,
                data?.business_name,
                data?.regions?.regions_name,
                data?.enabled,
                data?._id,
                data?.regions_id,
              ];
            });
            setBusinessData(tabledata);
            setPage(response?.data?.currentPage);

            setSortBy(response?.data?.sortBy);
            setSortOrder(response?.data?.sortOrder);

            setLoading(false);
          })
          .catch((error) => {
            console.error("API Error:", error);
            setLoading(false);
          })
      : axiosConfig
          .get(apiRoutes?.adminGetBusiness, {
            params: { sortBy: column_name, sortOrder: "asc" },
          })
          .then((response) => {
            const depts = response?.data?.data;
            const tabledata = depts?.map((data, i) => {
              return [
                i + 1,
                data?.business_name,
                data?.regions?.regions_name,
                data?.enabled,
                data?._id,
                data?.regions_id,
              ];
            });
            setBusinessData(tabledata);
            setPage(response?.data?.currentPage);

            setSortBy1(response?.data?.sortBy);
            setSortOrder1(response?.data?.sortOrder);

            setLoading(false);
          })
          .catch((error) => {
            console.error("API Error:", error);
            setLoading(false);
          });
  };

  const handleSortDescending = (column_name) => {
    column_name === "business_name"
      ? axiosConfig
          .get(apiRoutes?.adminGetBusiness, {
            params: { sortBy: column_name, sortOrder: "desc" },
          })
          .then((response) => {
            const depts = response?.data?.data;
            const tabledata = depts?.map((data, i) => {
              return [
                i + 1,
                data?.business_name,
                data?.regions?.regions_name,
                data?.enabled,
                data?._id,
                data?.regions_id,
              ];
            });
            setBusinessData(tabledata);
            setPage(response?.data?.currentPage);

            setSortBy(response?.data?.sortBy);
            setSortOrder(response?.data?.sortOrder);

            setLoading(false);
          })
          .catch((error) => {
            console.error("API Error:", error);
            setLoading(false);
          })
      : axiosConfig
          .get(apiRoutes?.adminGetBusiness, {
            params: { sortBy: column_name, sortOrder: "desc" },
          })
          .then((response) => {
            const depts = response?.data?.data;
            const tabledata = depts?.map((data, i) => {
              return [
                i + 1,
                data?.business_name,
                data?.regions?.regions_name,
                data?.enabled,
                data?._id,
                data?.regions_id,
              ];
            });
            setBusinessData(tabledata);
            setPage(response?.data?.currentPage);

            setSortBy1(response?.data?.sortBy);
            setSortOrder1(response?.data?.sortOrder);

            setLoading(false);
          })
          .catch((error) => {
            console.error("API Error:", error);
            setLoading(false);
          });
  };

  const columns = [
    {
      name: "#",
      options: {
        filter: false,
        searchable: false,
        sort: false,
        customHeadRender: (columnMeta) => {
          return <th className="rightAlign">{columnMeta.name}</th>;
        },
        customBodyRender: (columnMeta) => {
          return <td className="rightAlign">{columnMeta}</td>;
        },
      },
    },
    {
      name: "Business Unit",
      sortThirdClickReset: true,
      options: {
        filter: true,
        customHeadRender: (columnMeta) => {
          return (
            <th className="leftAlign">
              {columnMeta.name}
              {sortOrder === "asc" ? (
                <IconButton
                  onClick={() => {
                    handleSortDescending("business_name");
                  }}
                >
                  <ArrowUpwardIcon />
                </IconButton>
              ) : (
                <IconButton
                  onClick={() => {
                    handleSortAscending("business_name");
                  }}
                >
                  <ArrowDownwardIcon />
                </IconButton>
              )}
            </th>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <>
              <td className="leftAlign">
                {inEditingMode[4] === tableMeta?.rowData[4] ? (
                  <input
                    autoFocus
                    type="text"
                    value={inlineValue}
                    //  onChange={(e) => setInlineValue(e.target.value)}
                    onChange={settingNewBusinessEdit}
                    maxLength="51"
                    onKeyDown={(e) => {
                      if (e.code === "Enter" || e.code === "NumpadEnter")
                        editBusiness();
                    }}
                  />
                ) : (
                  <div>{value}</div>
                )}
              </td>
            </>
          );
        },
      },
    },
    {
      name: "Region",
      options: {
        filter: true,
        sortThirdClickReset: true,

        customHeadRender: (columnMeta) => {
          return (
            <th className="leftAlign">
              {columnMeta.name}
              {sortOrder1 === "asc" ? (
                <IconButton
                  onClick={() => {
                    handleSortDescending("reasons.regions_name");
                  }}
                >
                  <ArrowUpwardIcon />
                </IconButton>
              ) : (
                <IconButton
                  onClick={() => {
                    handleSortAscending("reasons.regions_name");
                  }}
                >
                  <ArrowDownwardIcon />
                </IconButton>
              )}
            </th>
          );
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <td className="leftAlign">
              <div>{value}</div>
            </td>
          );
        },
      },
    },
    {
      name: "Status",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid container className="actionIcons toggleSwitch">
              <Grid item display="flex">
                <FormControlLabel
                  value={value ? true : false}
                  control={
                    <Switch
                      color="green"
                      checked={value}
                      value={value ? true : false}
                      className="grid-action-toggle"
                    />
                  }
                  onChange={() => {
                    setInlineValue(false);
                    setInEditingMode(false);
                    setCurrentRowData(tableMeta?.rowData);
                    if (value) {
                      setOpen(true);
                    } else {
                      enableDisableBusiness(tableMeta?.rowData);
                    }
                  }}
                />
              </Grid>
            </Grid>
          );
        },
      },
    },
    {
      name: "Action",
      options: {
        filter: false,
        sort: false,
        searchable: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid container className="actionIcons">
              <Tooltip title="Edit">
                <Grid item display="flex">
                  {inEditingMode[4] === tableMeta?.rowData[4] ? (
                    <FaCheck
                      className="gridEditIcon"
                      onClick={() => {
                        editBusiness();
                      }}
                    />
                  ) : (
                    <FaPencilAlt
                      className="gridEditIcon"
                      onClick={() => {
                        setInlineValue(tableMeta?.rowData[1]);
                        setInEditingMode(tableMeta?.rowData);
                      }}
                    />
                  )}
                </Grid>
              </Tooltip>
              <Grid item display="flex">
                {inEditingMode[3] === tableMeta?.rowData[3] && (
                  <Tooltip title="Cancel">
                    <Grid item className="cross-icon">
                      <FaTimes
                        className="gridEditIcon"
                        onClick={() => {
                          setInlineValue(false);
                          setInEditingMode(false);
                        }}
                      />
                    </Grid>
                  </Tooltip>
                )}
              </Grid>
            </Grid>
          );
        },
      },
    },
    {
      name: "id",
      options: {
        display: false,
        filter: false,
        sort: false,
        viewColumns: false,
      },
    },
    {
      name: "regions_id",
      options: {
        display: false,
        filter: false,
        sort: false,
        viewColumns: false,
      },
    },
  ];

  const handleFetchPageData = (newpage, newlimit) => {
    getBusiness(newpage, limit);
  };
  return (
    <>
      <PageTitle title="Business Units" />
      <div className="table-upper search_input_field">
        <div className="search_table ">
          <AiOutlineSearch />
          <input
            id="standard-basic"
            label="Search"
            defaultValue={searchText}
            placeholder="Search"
            onChange={(e) => searchTextData(e.target.value)}
            maxLength={50}
          />
        </div>

        <Grid container spacing={4} className={classes.addNew}>
          <div className="addNewDivSelect">
            <FormControl
              variant="outlined"
              className={classes.formControl}
              size="small"
            >
              <Select
                value={selectValue}
                onChange={(i) => {
                  setSelectValue(i?.target.value);
                }}
                MenuProps={{
                  classes: { paper: classes.menuPaper },
                }}
              >
                <MenuItem value={"-1"} className="select-none">
                  Select Region
                </MenuItem>
                {regionData &&
                  Array.isArray(regionData) &&
                  regionData?.map((data, i) => {
                    return (
                      data?.enabled && (
                        <MenuItem key={i} value={data?._id}>
                          {data?.regions_name}
                        </MenuItem>
                      )
                    );
                  })}
              </Select>
            </FormControl>
          </div>
          <div className={classes.addNewDiv}>
            <TextField
              value={newBusiness}
              size="small"
              className={classes.addNewText}
              variant="outlined"
              onChange={settingNewBusiness}
              onKeyDown={(e) => {
                if (e.code === "Enter" || e.code === "NumpadEnter")
                  addNewBusiness();
              }}
            />
          </div>
          <div className={classes.addNewDiv1}>
            <Button
              className={classes.addNewButton}
              onClick={() => addNewBusiness()}
              variant="outlined"
            >
              Add
            </Button>
          </div>
        </Grid>
      </div>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {!loading && (
            <MUIDataTable
              rowsPerPage={-1}
              data={businessData ? businessData : []}
              columns={columns}
              className="custom-table-outer"
              options={{
                filterType: "checkbox",
                download: false,
                print: false,
                selectableRows: "none",
                setTableProps: () => ({ className: "custom-table-design" }),
                textLabels: {
                  body: {
                    noMatch: "No data found",
                  },
                },
                pagination: false,
                searchText: searchText,
                search: false,
                setCellProps: () => ({ style: { maxWidth: "159px" } }),
                viewColumns: false,
                filter: false,
              }}
            />
          )}

          <CustomIcons
            totalPages={totalPages}
            setTotalPages={setTotalPages}
            setPage={setPage}
            pageNew={page}
            onRowsPerPageChange={handleRowsPerPageChange}
            handleFetchPageData={handleFetchPageData}
          />
        </Grid>
      </Grid>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={false}
        maxWidth={"sm"}
      >
        <div className="popup-main">
          <DialogTitle id="alert-dialog-title" className="popup-title-outer">
            <Box display="flex" alignItems="center">
              <Box flexGrow={1} className="popup-title"></Box>
              <Box className="popup-icon">
                <IconButton onClick={() => setOpen(false)}>
                  <RxCross2 />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              className="popup-text"
            >
              Are you sure you want to disable{" "}
              <b>{currentRowData && currentRowData[1]}</b> business unit?
            </DialogContentText>
          </DialogContent>
          <DialogActions className={"popup-button-outer"}>
            <Button
              variant="contained"
              className={"btn-design-theme yes-button"}
              onClick={() => enableDisableBusiness(currentRowData)}
            >
              Yes
            </Button>
            <Button
              variant="contained"
              className="btn-design-theme cancel-button"
              onClick={() => setOpen(false)}
            >
              No
            </Button>
          </DialogActions>
        </div>
      </Dialog>
      <ToastContainer />
    </>
  );
}
