import { baseurl } from "./baseUrl"

export const apiRoutes = {
    // admin
    adminRegister: `${baseurl}/adminregister`,
    adminLogin: `${baseurl}/login`,
    adminVerifyCaptcha: `${baseurl}/captcha`,
    adminLogout: `${baseurl}/logout`,
    adminGetSingleUserDetailsAll: `${baseurl}/getsingleuserdetail`,
    adminForgotPassword: `${baseurl}/forgotpassword`,
    adminResetPassword: `${baseurl}/resetpassword`,
    // admin users
    adminGetUserData: `${baseurl}/getsingeluser/detailwithtoken`,
    // admin user roles
    adminGetUserRoles: `${baseurl}/get/data/`,
    adminEditUserRole: `${baseurl}/user/role/edit`,
    adminGetSingleUserRole: `${baseurl}/get/singe/user/data`,
    adminAddUserRole: `${baseurl}/add/user/role`,
    adminGetActiveUserRole: `${baseurl}/active_user_roles`,
    // admin departments
    adminGetDepartment: `${baseurl}/get/user/departments`,
    adminAddDepartment: `${baseurl}/add/user/department`,
    adminDeleteDepartment: `${baseurl}/delete/user/department`,
    adminUpdateDepartment: `${baseurl}/update/user/department`,
    // admin Regions
    adminGetRegion: `${baseurl}/get/user/regions`,
    adminUpdateRegion: `${baseurl}/update/user/regions`,
    adminDeleteRegion: `${baseurl}/delete/user/region`,
    adminAddRegion: `${baseurl}/add/user/regions`,
    adminUpdateBusinessRegion: `${baseurl}/update/business/regions`,
    // admin business units
    adminGetBusiness: `${baseurl}/get/all/business`,
    adminUpdateBusiness: `${baseurl}/edit/business`,
    adminDeleteBusiness: `${baseurl}/delete/business`,
    adminAddBusiness: `${baseurl}/add/new/business`,
    // admin permissions
    adminGetAllPermissions: `${baseurl}/get/all/permission`,
    // user requests
    adminGetUserRequests: `${baseurl}/get/business/requests`,
    // user
    // user register
    userRegister: `${baseurl}/add/contributer/register`,
    // user requests
    userRequestsBulkDelete: `${baseurl}/delete/record/requests`,
    userRequestAction: `${baseurl}/user_request/action`,
}
