import { makeStyles } from "@material-ui/styles";

export default makeStyles((theme) => ({
  addNew: {
    marginBottom: "16px",
    justifyContent: "end",
    paddingRight: "20px",
    "& button": {
      background: "#0056B8",
      color: "#fff",
      width: " 100%",
      height: "100%",
      borderRadius: "0 5px 5px 0",
      fontWeight: "600",
      transition: "all 0.4s",
      textTransform: "capitalize",
      "&:hover": {
        background: "transparent",
        color: "#0056B8",
        width: " 100%",
        height: "100%",
        border: "1px solid #0056B8",
        borderRadius: "0 5px 5px 0",
        fontWeight: "600",
      },
    },
  },
  addNewText: {
    "& fieldset": {
      borderRight: "0",
      width: "100%",
      borderRadius: "5px 0 0 5px",
    },
  },
  addNewDiv: {
    alignItems: "center",
    display: "flex",
  },
  addNewDiv1: {
    maxWidth: "120px",
    display: "flex",
    height: "40px",
  },
  addNewButton: {},
  formControl: {
    minWidth: 200,
    "& .MuiOutlinedInput-root": {
      borderRadius: "5px",
    },
  },
  menuPaper: {
    maxHeight: "300px",
    top: "218px !important",
  },
}));
