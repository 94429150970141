import React, { useRef, useState } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  Fade,
} from "@material-ui/core";

import useStyles from "./styles";
import { useHistory } from "react-router-dom";
import { routeConfigs } from "../../utils/routeConfig";
import ReCAPTCHA from "react-google-recaptcha";
import { recaptcha_site_key } from "../../utils/envConst";
import axios from "axios";
import { apiRoutes } from "../../utils/apiRoutes";
import { getCurrentEncodedDate } from "../../utils/dateUtils";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { checkValidEmail } from "../../utils/validationUtils";

function ForgotPassword() {
  const classes = useStyles();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [emailValue, setEmailValue] = useState("");

  const captchaRef = useRef(null);
  const clearTimerRef = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    clearTimeout(clearTimerRef.current);
    const captchaToken = captchaRef.current.getValue();
    const currentBaseDate = getCurrentEncodedDate();
    const isValidEmail = checkValidEmail(emailValue);
    setIsLoading(true);
    setErrorMessage(false);
    if (!emailValue) {
      setError(true);
      setErrorMessage("Please enter the mandatory email address");
      setIsLoading(false);
    } else if (!isValidEmail) {
      setError(true);
      setErrorMessage("Please enter the valid email address");
      setIsLoading(false);
    } else if (!captchaToken) {
      setError(true);
      setErrorMessage("Please enter the mandatory CAPTCHA field");
      setIsLoading(false);
    } else {
      const body = {
        email: emailValue,
        basecurruntdate: currentBaseDate,
        is_admin: true,
      };
      setTimeout(() => {
        axios
          .post(apiRoutes.adminForgotPassword, body)
          .then((response) => {
            setErrorMessage("");
            setError(false);
            setEmailValue("");
            setIsLoading(false);
            toast("Email sent successfully", {
              progressClassName: "sucessToastProgress",
              bodyClassName: "sucessToastBody",
            });
            captchaRef.current.reset();
          })
          .catch((error) => {
            const errmsg = error?.response?.data?.message;
            if (errmsg) {
              setErrorMessage(errmsg);
            }
            setError(true);
            setIsLoading(false);
          });
      }, 1500);
    }

    clearTimerRef.current = setTimeout(() => {
      setError(false);
      setErrorMessage("");
    }, 13420);
  };

  function handleEmailChange(e) {
    if (e.target.value.length <= 300) {
      setEmailValue(e.target.value);
    }
    setErrorMessage("");
    setError(false);
  }

  function handleCaptchaChange(e) {
    setErrorMessage("");
    setError(false);
  }

  return (
    <Grid container className={classes.container}>
      {/* <div className={classes.logotypeContainer}>
                <img src="./logos/full-stack-white.svg" alt="logo" className={classes.logotypeImage} />
            </div> */}
      <img
        src="./images/loginBackground2.jpg"
        alt="logo"
        className={classes.logotypeContainer}
      />
      <div className={classes.formContainer}>
        <img
          src="./logos/full-stack-color.svg"
          alt="logo"
          className={classes.logotypeImage}
        />
        <div className={classes.form}>
          <Tabs value={0} indicatorColor="primary" textColor="primary" centered>
            <Tab label="Forgot Password" classes={{ root: classes.tab }} />
          </Tabs>
          <Typography className={classes.forgotPassText}>
            Please enter your email ID. You will receive a reset password link
            in the mail.
          </Typography>
          <form onSubmit={handleSubmit}>
            <React.Fragment>
              <Fade in={error}>
                <Typography
                  color="secondary"
                  className={`${classes.marginTop} ${classes.errorMessage} `}
                >
                  {errorMessage}
                </Typography>
              </Fade>
              <TextField
                id="email"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={emailValue}
                onChange={handleEmailChange}
                margin="normal"
                placeholder="Email"
                type="text"
                fullWidth
                autoComplete="on"
                maxLength="300"
              />
              <ReCAPTCHA
                className={classes.loginRecaptcha}
                ref={captchaRef}
                sitekey={recaptcha_site_key}
                onChange={handleCaptchaChange}
              />
              <div className={classes.formButtons}>
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    type="submit"
                  >
                    Submit
                  </Button>
                )}
                <Button
                  color="primary"
                  size="large"
                  className={classes.forgetButton}
                  onClick={() => history.push(routeConfigs.adminLogin)}
                >
                  Back to Login
                </Button>
              </div>
            </React.Fragment>
          </form>
        </div>
        <Typography color="primary" className={classes.copyright}>
          © 1902-{new Date().getFullYear()}{" "}
          <a
            style={{ textDecoration: "none", color: "inherit" }}
            href="http://tcdam.csdevhub.com/"
            rel="noopener noreferrer"
            target="_blank"
          >
            TCDAM
          </a>
          , LLC. All rights reserved.
        </Typography>
      </div>
    </Grid>
  );
}

export default ForgotPassword;
