import * as React from "react";
import Pagination from "@material-ui/lab/Pagination";
import PaginationItem from "@material-ui/lab/PaginationItem";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

export function CustomIcons(props) {
  const {
    totalPages,
    setTotalPages,
    setPage,
    pageNew,
    onRowsPerPageChange,
    handleFetchPageData,
  } = props;

  /** selecting rows per page */
  const handleRowsPerPageChange = (e) => {
    onRowsPerPageChange(e.target.value);
  };

  console.log("page", totalPages, pageNew);
  return (
    <div className="pagination-cstm-dropdown">
      <Pagination
        page={pageNew}
        count={totalPages}
        onChange={(event, page) => {
          handleFetchPageData(page);
          setPage(page);
        }}
        className="custom-pagination"
        renderItem={(item) => (
          <PaginationItem
            slots={{ previous: ArrowLeftIcon, next: ArrowRightIcon }}
            {...item}
          />
        )}
      />
      <div className="custom_select_box">
        <select onChange={handleRowsPerPageChange}>
          <option>{10}</option>
          <option>{20}</option>
          <option>{30}</option>
        </select>
      </div>
    </div>
  );
}
