import React, { useEffect, useState } from "react";
import PageTitle from "../../components/PageTitle/PageTitle";
import {
  Box,
  Button,
  Grid,
  Select,
  Switch,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  IconButton,
  FormControl,
  TextField,
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import axiosConfig from "../../utils/axiosConfig";
import { apiRoutes } from "../../utils/apiRoutes";
import { FaCheck, FaPencilAlt, FaTimes } from "react-icons/fa";
import useStyles from "./styles";
import { RxCross2 } from "react-icons/rx";
import { toast } from "react-toastify";
import { AiOutlineSearch } from "react-icons/ai";
import { CustomIcons } from "../CustomIcons";
import Tooltip from "@material-ui/core/Tooltip";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward";

export default function UserRoles() {
  const [rolesData, setRolesData] = useState(null);
  const [open, setOpen] = useState(false);
  const [currentRowData, setCurrentRowData] = useState(null);
  const [selectValue, setSelectValue] = useState("-1");
  const classes = useStyles();
  const [showReassign, setShowReassign] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [inEditingMode, setInEditingMode] = useState(false);
  const [inlineValue, setInlineValue] = useState("");
  const [newRole, setNewRole] = useState("");
  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [sortBy, setSortBy] = useState("USER ROLE");
  const [sortOrder, setSortOrder] = useState("asc");

  useEffect(() => {
    adminGetUserRoles();
  }, []);

  const settingNewDepartment = (e) => {
    const value = e.target.value;
    const alphabeticRegex = /^[a-zA-Z\s]*$/;
    if (e.target.value.length <= 50 && alphabeticRegex.test(value)) {
      setNewRole(e.target.value);
    }
  };

  const settingNewDepartmentEdit = (e) => {
    const value = e.target.value;
    const alphabeticRegex = /^[a-zA-Z\s]*$/;
    if (e.target.value.length <= 50 && alphabeticRegex.test(value)) {
      setInlineValue(e.target.value);
    }
  };

  function adminGetUserRoles(newPage = page, limitN = limit, rep = false) {
    if (!rep) setLoading(true);
    axiosConfig
      .get(apiRoutes.adminGetUserRoles, {
        params: { page: newPage, limit: limitN },
      })
      .then((response) => {
        const roleData = response?.data?.role_data;
        const tabledata = roleData?.map((data, i) => {
          return [
            response?.data?.limit * (response?.data.currentPage - 1) + i + 1,
            data.role,
            data.enabled,
            data._id,
            data.permissions,
            data.editable,
          ];
        });
        const filteredTableData = tabledata.filter(
          (role) => role[1] !== "Super Admin",
        );
        setRolesData(filteredTableData);
        setPage(response?.data?.currentPage);
        setTotalPages(response?.data?.totalPages);
        setLimit(response?.data?.limit);

        setLoading(false);
        setNewRole("");
        setInlineValue("");
      })
      .catch((error) => {});
  }

  const handleFetchPageData = (newpage) => {
    adminGetUserRoles(newpage, limit);
  };
  const handleRowsPerPageChange = (newRowsPerPage) => {
    setLimit(+newRowsPerPage);
    adminGetUserRoles(1, +newRowsPerPage);
    setCurrentPage(1);
  };

  function handleEditRoleName(rowDataArr, inlineValue) {
    const data = rowDataArr;
    data[1] = inlineValue;
    setInEditingMode(false);
    setInlineValue("");
    handleUpdateRole(data);
  }

  function handleAddNewRole() {
    const trimmedNewRole = newRole && newRole.trim();
    if (
      trimmedNewRole === undefined ||
      trimmedNewRole === null ||
      trimmedNewRole === ""
    ) {
      toast.error("Please enter the role name.", {
        bodyClassName: "font-color-black",
        autoClose: 8000,
      });
      return false;
    }

    const body = {
      role: trimmedNewRole,
    };
    axiosConfig
      .post(apiRoutes.adminAddUserRole, body)
      .then((response) => {
        toast("Role added successfully!", {
          bodyClassName: "sucessToastBody",
          progressClassName: "sucessToastProgress",
          autoClose: 8000,
        });
        adminGetUserRoles();
      })
      .catch((error) => {
        if (error.response.status === 409 || error.response.status === "409") {
          toast.error("Role name must be unique.", {
            bodyClassName: "font-color-black",
            autoClose: 8000,
          });
        }
      });
  }

  const handleClose = () => {
    setShowReassign(false);
    setOpen(false);
    setSelectValue("-1");
  };

  function searchTextData(e) {
    let role = e;
    axiosConfig
      .get(apiRoutes.adminGetUserRoles, {
        params: { search: role != "" ? role : null, limit: limit },
      })
      .then((response) => {
        const userRoles = response?.data?.role_data;
        const tabledata = userRoles?.map((data, i) => {
          return [i + 1, data.role, data.enabled, data._id,data.permissions,data.editable];
        });

        setRolesData(tabledata);

        setLoading(false);
      })
      .catch((error) => {
        console.error("API Error:", error);
        setLoading(false);
      });
  }

  function handleNotReassign() {
    const data = currentRowData;
    data[2] = !data[2];
    handleUpdateRole(data);
    handleClose();
  }

  function handleReassign(rowdata) {
    const data = rowdata;
    data[2] = !data[2];
    if (selectValue === "-1") {
      toast.error("Please select a user role", {
        bodyClassName: "font-color-black",
        autoClose: 8000,
      });
    } else {
      handleUpdateRole(rowdata);
    }
  }

  function handleUpdateRole(rowdata) {
    setTimeout(() => {
      const permission =
        rowdata[4] && Array.isArray(rowdata[4]) ? rowdata[4] : [];
      const body = {
        roleId: rowdata[3],
        role: rowdata[1],
        isenabled: rowdata[2],
        permissions: permission,
      };
      axiosConfig
        .put(apiRoutes.adminEditUserRole, body)
        .then(() => {
          adminGetUserRoles(page,limit,true);
          toast("Role updated successfully!", {
            bodyClassName: "sucessToastBody",
            progressClassName: "sucessToastProgress",
            autoClose: 8000,
          });
        })
        .catch((error) => {
          error?.response?.data?.message &&
            toast.error(error?.response?.data?.message, {
              bodyClassName: "font-color-black",
              autoClose: 8000,
            });
        });
      handleClose();
    }, 500);
  }

  const handleSortAscending = () => {
    axiosConfig
      .get(apiRoutes.adminGetUserRoles, {
        params: { sortBy: "role", sortOrder: "asc", limit },
      })
      .then((response) => {
        const roleData = response?.data?.role_data;
        const tabledata = roleData?.map((data, i) => {
          return [
            i + 1,
            data.role,
            data.enabled,
            data._id,
            data.permissions,
            data.editable,
          ];
        });
        const filteredTableData = tabledata.filter(
          (role) => role[1] !== "Super Admin",
        );
        setRolesData(filteredTableData);
        setPage(response.data.currentPage);
        setSortBy(response?.data?.sortBy);
        setSortOrder(response?.data?.sortOrder);

        setLoading(false);
      })
      .catch((error) => {
        console.error("API Error:", error);
        setLoading(false);
      });
  };

  const handleSortDescending = () => {
    axiosConfig
      .get(apiRoutes.adminGetUserRoles, {
        params: { sortBy: "role", sortOrder: "desc", limit },
      })
      .then((response) => {
        const roleData = response?.data?.role_data;
        const tabledata = roleData?.map((data, i) => {
          return [
            i + 1,
            data.role,
            data.enabled,
            data._id,
            data.permissions,
            data.editable,
          ];
        });
        const filteredTableData = tabledata.filter(
          (role) => role[1] !== "Super Admin",
        );
        setRolesData(filteredTableData);
        setPage(response.data.currentPage);
        setSortBy(response?.data?.sortBy);
        setSortOrder(response?.data?.sortOrder);

        setLoading(false);
      })
      .catch((error) => {
        console.error("API Error:", error);
        setLoading(false);
      });
  };

  function handleSwitchOnChange(rowData, switchValue) {
    setInlineValue(false);
    setInEditingMode(false);
    setCurrentRowData(rowData);
    if (switchValue) {
      axiosConfig(apiRoutes.adminGetActiveUserRole)
        .then((response) => {
          if (response?.data?.total_roles > 2) {
            setOpen(true);
          } else {
            toast.error("At least one user role should be enabled.", {
              bodyClassName: "font-color-black",
              autoClose: 8000,
            });
          }
        })
        .catch((error) => {});
    } else {
      const data = rowData;
      data[2] = !data[2];
      handleUpdateRole(data);
    }
  }

  const columns = [
    {
      name: "#",
      options: {
        filter: false,
        searchable: false,
        sort: false,
        customHeadRender: (columnMeta) => {
          return <th className="rightAlign">{columnMeta.name}</th>;
        },
        customBodyRender: (columnMeta) => {
          return <td className="rightAlign">{columnMeta}</td>;
        },
      },
    },
    {
      name: "User Role",
      options: {
        filter: true,
        maxLength: "50",
        customHeadRender: (columnMeta) => {
          return (
            <th className="leftAlign">
              {columnMeta.name}
              {sortOrder === "asc" ? (
                <IconButton
                  onClick={() => {
                    handleSortDescending();
                  }}
                >
                  <ArrowUpwardIcon />
                </IconButton>
              ) : (
                <IconButton
                  onClick={() => {
                    handleSortAscending();
                  }}
                >
                  <ArrowDownwardIcon />
                </IconButton>
              )}
            </th>
          );
        },
        customBodyRender: (
          value,
          tableMeta,
          updateValue,
          columnMeta,
          updateDirection,
          sortOrder,
        ) => (
          <td className="leftAlign">
            {inEditingMode[3] === tableMeta?.rowData[3] ? (
              <input
                autoFocus
                type="text"
                value={inlineValue}
                // onChange={(e) => setInlineValue(e.target.value)}
                onChange={settingNewDepartmentEdit}
                maxLength="50"
                onKeyDown={(e) => {
                  if (e.code === "Enter" || e.code === "NumpadEnter")
                    handleEditRoleName(tableMeta?.rowData, inlineValue);
                }}
              />
            ) : (
              <div>{value}</div>
            )}
          </td>
        ),
      },
    },

    {
      name: "Status",
      options: {
        filter: false,
        searchable: false,
        sort: false,
        customHeadRender: (columnMeta) => {
          return <th className="centerAlign">{columnMeta.name}</th>;
        },
        customBodyRender: (value, tableMeta) => {
          return (
            <td className="centerAlign">
              <Grid container className="actionIcons toggleSwitch">
                <Grid item>
                  <FormControlLabel
                    value={value ? true : false}
                    control={
                      <Switch
                        color="green"
                        checked={value}
                        value={value ? true : false}
                        className="grid-action-toggle"
                      />
                    }
                    onChange={() => {
                      handleSwitchOnChange(tableMeta?.rowData, value);
                    }}
                  />
                </Grid>
              </Grid>
            </td>
          );
        },
      },
    },
    {
      name: "Action",
      options: {
        filter: false,
        searchable: false,
        sort: false,
        customHeadRender: (columnMeta) => {
          return <th className="centerAlign">{columnMeta.name}</th>;
        },
        customBodyRender: (value, tableMeta) => {
          return (
            <td className="centerAlign">
              <Grid container className="actionIcons">
                {tableMeta.rowData[5] && tableMeta?.rowData[2] && (
                  <Tooltip title="Edit">
                    <Grid item className="pencil-icon">
                      {inEditingMode[3] === tableMeta?.rowData[3] ? (
                        <FaCheck
                          onClick={() => {
                            handleEditRoleName(tableMeta?.rowData, inlineValue);
                          }}
                          className="gridEditIcon"
                        />
                      ) : (
                        <FaPencilAlt
                          onClick={() => {
                            setInlineValue(tableMeta?.rowData[1]);
                            setInEditingMode(tableMeta?.rowData);
                          }}
                          className="gridEditIcon"
                        />
                      )}
                    </Grid>
                  </Tooltip>
                )}
                <Grid item display="flex">
                  {inEditingMode[3] === tableMeta?.rowData[3] && (
                    <Tooltip title="Cancel">
                      <Grid item className="cross-icon">
                        <FaTimes
                          className="gridEditIcon"
                          onClick={() => {
                            setInlineValue(false);
                            setInEditingMode(false);
                          }}
                        />
                      </Grid>
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
            </td>
          );
        },
      },
    },
    {
      name: "id",
      options: {
        display: false,
        filter: false,
        sort: false,
        viewColumns: false,
        searchable: false,
      },
    },
    {
      name: "permissions",
      options: {
        display: false,
        filter: false,
        sort: false,
        viewColumns: false,
        searchable: false,
      },
    },
  ];

  return (
    <>
      <PageTitle title="User Roles" />
      <div className="table-upper">
        <div className="search_table">
          <AiOutlineSearch />
          <input
            id="standard-basic"
            label="Search"
            defaultValue={searchText}
            placeholder="Search"
            onChange={(e) => searchTextData(e.target.value)}
            maxLength={50}
          />
        </div>
        <Grid container spacing={4} className={classes.addNew}>
          <div className={classes.addNewDiv}>
            <TextField
              value={newRole}
              size="small"
              className={classes.addNewText}
              variant="outlined"
              onChange={settingNewDepartment}
              onKeyDown={(e) => {
                if (e.code === "Enter" || e.code === "NumpadEnter")
                  handleAddNewRole();
              }}
            />
          </div>
          <div className={classes.addNewDiv1}>
            <Button
              className={classes.addNewButton}
              onClick={() => handleAddNewRole()}
              variant="outlined"
            >
              Add
            </Button>
          </div>
        </Grid>
      </div>

      <Grid container spacing={4}>
        <Grid item xs={12}>
          {!loading && (
            <MUIDataTable
              rowsPerPage={-1}
              data={rolesData ? rolesData : []}
              columns={columns}
              className="custom-table-outer"
              options={{
                filterType: "checkbox",
                selectableRows: "none",
                download: false,
                print: false,
                filter: false,
                setCellProps: () => ({ style: { maxWidth: "159px" } }),
                setTableProps: () => ({ className: "custom-table-design" }),
                searchText: searchText,
                search: false,
                viewColumns: false,
                pagination: false,
                textLabels: {
                  body: {
                    noMatch: "No data found",
                  },
                },
              }}
            />
          )}
          <CustomIcons
            totalPages={totalPages}
            setTotalPages={setTotalPages}
            setPage={setPage}
            pageNew={page}
            onRowsPerPageChange={handleRowsPerPageChange}
            handleFetchPageData={handleFetchPageData}
          />
        </Grid>
      </Grid>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={"sm"}
      >
        <div className="popup-main">
          <DialogTitle id="alert-dialog-title" className="popup-title-outer">
            <Box display="flex" alignItems="center">
              <Box flexGrow={1} className="popup-title">
                {" "}
                {"Reassign User Role"}
              </Box>
              <Box className="popup-icon">
                <IconButton onClick={handleClose}>
                  <RxCross2 />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              className="popup-text"
            >
              Would you like to reassign associated users?
            </DialogContentText>
          </DialogContent>
          <DialogActions className={"popup-button-outer"}>
            <Button
              variant="contained"
              className={
                showReassign
                  ? "btn-design-theme yes-button"
                  : "btn-design-theme"
              }
              onClick={() => setShowReassign(true)}
            >
              Yes
            </Button>
            <Button
              variant="contained"
              className="btn-design-theme cancel-button"
              onClick={handleNotReassign}
            >
              No
            </Button>
          </DialogActions>
          {showReassign && (
            <div className={classes.reassignDiv}>
              <DialogContent className="userRoleText">
                <DialogContentText
                  id="alert-dialog-description"
                  className="popup-text"
                >
                  Please select a new user role from the options below.
                </DialogContentText>
                <div className={classes.flexCenter}>
                  User Role
                  <FormControl
                    variant="outlined"
                    className={classes.modalSelect}
                    size="small"
                  >
                    <select
                      className="permission-role-select"
                      value={selectValue}
                      onChange={(i) => {
                        setSelectValue(i?.target.value);
                      }}
                      //  MenuProps={{ classes: { paper: classes.menuPaper } }}
                    >
                      <option value={"-1"} className="select-none">
                        Select
                      </option>
                      {rolesData?.map((data, i) => {
                        if (data[2] && data[3] !== currentRowData[3]) {
                          return (
                            <option key={i} value={data[3]}>
                              {data[1]}
                            </option>
                          );
                        }
                        return null;
                      })}
                    </select>
                    <div class="selectArrow">
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        stroke-width="0"
                        viewBox="0 0 24 24"
                        height="1em"
                        width="1em"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M12 16L6 10H18L12 16Z"></path>
                      </svg>
                    </div>
                  </FormControl>
                </div>
              </DialogContent>
              <DialogActions className={"popup-button-outer"}>
                <Button
                  variant="contained"
                  className={"btn-design-theme"}
                  onClick={() => handleReassign(currentRowData)}
                >
                  Save
                </Button>
                <Button
                  variant="contained"
                  className={"btn-design-theme cancel-button"}
                  onClick={handleClose}
                >
                  Cancel
                </Button>
              </DialogActions>
            </div>
          )}
        </div>
      </Dialog>
    </>
  );
}
