import React from "react";
import PageTitle from "../../components/PageTitle/PageTitle";

export default function UsersAll() {
  return (
    <>
      <PageTitle title="All Users" />
    </>
  );
}
