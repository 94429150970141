import { Backdrop, Button, Fade, Modal, Typography } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react'
import { signOut, useUserHomeDispatch } from '../../context/UserHomeContext';
import { useIdleTimer } from 'react-idle-timer';
import { useHistory } from 'react-router-dom';

const IdleModal = () => {
    const id = useRef(null);
    const history = useHistory();
    const dispatch = useUserHomeDispatch();
    const clearTimerRef = useRef();
    const clear = () => window.clearInterval(id.current);
    const [open, setOpen] = useState(false);
    const [logout, setLogout] = useState(false);
    const [timer, setTimer] = useState(0);

    const modalText = `You have been inactive for more than 5 minutes.
    Click “I am here” to continue your session. If you are doing nothing,
    TCDAM will automatically end your session. Your session will expire in ${timer} seconds`;

    try {
        const onIdle = () => {
            setOpen(true);
            setLogout(true);
            setTimer(60)
        }
        useIdleTimer({
            onIdle,
            timeout: 300000,
            throttle: 500
        })
    }
    catch (e) { }

    useEffect(() => {
        clearTimerRef.current = setTimeout(() => {
            if (logout) {
                signOut(dispatch, history);
            }
        }, 60000)
        if (logout) {
            id.current = window.setInterval(() => {
                setTimer((time) => time - 1)
            }, 1000)
        }
        return () => clear();
        //eslint-disable-next-line
    }, [logout]);

    useEffect(() => {
        if (timer === 0) {
            clear()
        }
    }, [timer]);

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={`modal-class autoSignOutModal`}
            open={open}
            onClose={() => { setOpen(false); clearTimeout(clearTimerRef.current); }}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={open}>
                <div className={`paper-class autoSignOut-popupMain`}>
                    <Typography size="md">{modalText}</Typography>
                    <div className="autoSignOut-popupButtons">
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            type="button"
                            onClick={() => {
                                setOpen(false);
                                clearTimeout(clearTimerRef.current);
                            }}
                            className="btn-design-theme-one"
                        >
                            I am here
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            type="button"
                            onClick={() => {
                                signOut(dispatch, history);
                                setOpen(false);
                            }}
                            className="btn-design-theme-one"
                        >
                            Log Out
                        </Button>
                    </div>
                </div>
            </Fade>
        </Modal>
    )
}

export default IdleModal;
