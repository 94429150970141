import React, { useRef, useState } from "react";
import {
  CircularProgress,
  Fade,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import ReCAPTCHA from "react-google-recaptcha";
import { recaptcha_site_key } from "../../utils/envConst";
import { Link, useHistory } from "react-router-dom";
import useStyles from "./styles";
import { routeConfigs } from "../../utils/routeConfig";
import {
  checkPasswordStrength,
  specialCharsRegex,
  numberRegex,
  checkValidEmail,
  onlyAlphabetsSpaceRegex,
} from "../../utils/validationUtils";
import { RxCheckCircled, RxCrossCircled } from "react-icons/rx";
import axios from "axios";
import { apiRoutes } from "../../utils/apiRoutes";
import { Grid } from "@material-ui/core";

function UserRegister() {
  const history = useHistory();
  // Form Values
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [newPasswordValue, setNewPasswordValue] = useState("");
  const [confirmPasswordValue, setConfirmPasswordValue] = useState("");
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);
  const [showPasswordStrength, setShowPasswordStrength] = useState(false);
  const [passwordStrengthMessage, setPasswordStrengthMessage] =
    useState("Too Short");

  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const clearTimerRef = useRef();
  const captchaRef = useRef(null);
  const regCheckLength = /^.{8,20}$/;

  //Check if password is valid
  function checkPasswordValid(password) {
    if (!regCheckLength.test(password)) {
      return false;
    }
    if (!numberRegex.test(password)) {
      return false;
    }
    if (!specialCharsRegex.test(password)) {
      return false;
    }
  }

  function handleErrorMessage(state = false, message = "") {
    setError(state);
    setErrorMessage(message);
    setIsLoading(false);
  }

  function handleFirstNameChange(e) {
    if (e.target.value.length <= 50) {
      setFirstName(e.target.value);
      handleErrorMessage(false, "");
    }
  }

  function handleLastNameChange(e) {
    if (e.target.value.length <= 50) {
      setLastName(e.target.value);
      handleErrorMessage(false, "");
    }
  }

  function handleEmailChange(e) {
    if (e.target.value.length <= 300) {
      setEmail(e.target.value);
      handleErrorMessage(false, "");
    }
  }

  function handleNewPasswordValueChange(e) {
    if (!/\s/g.test(e.target.value) && e.target.value.length <= 20) {
      setNewPasswordValue(e.target.value);
      const passMs = checkPasswordStrength(e.target.value);
      setPasswordStrengthMessage(passMs);
    }
    handleErrorMessage(false, "");
  }

  function handleConfirmPasswordValueChange(e) {
    if (!/\s/g.test(e.target.value) && e.target.value.length <= 20) {
      setConfirmPasswordValue(e.target.value);
    }
    handleErrorMessage(false, "");
  }

  function handleCaptchaChange() {
    handleErrorMessage(false, "");
  }

  function handleRegisterFormSubmit(e) {
    e.preventDefault();
    setIsLoading(true);
    handleErrorMessage(false, "");
    clearTimeout(clearTimerRef.current);

    const captchaToken = captchaRef.current.getValue();
    const passIsValid = checkPasswordValid(newPasswordValue);
    const isValidEmail = checkValidEmail(email);

    //trimmed values
    const trimFirstName = firstName?.trim();
    const trimLastName = lastName?.trim();
    const trimEmail = email?.trim();

    const isValidFName = onlyAlphabetsSpaceRegex.test(trimFirstName);
    const isValidLName = onlyAlphabetsSpaceRegex.test(trimLastName);

    // validations start
    if (!trimFirstName) {
      handleErrorMessage(true, "Please enter your first name.");
    } else if (!isValidFName) {
      handleErrorMessage(true, "Please enter the valid First Name.");
    } else if (!trimLastName) {
      handleErrorMessage(true, "Please enter your last name.");
    } else if (!isValidLName) {
      handleErrorMessage(true, "Please enter the valid Last Name.");
    } else if (!trimEmail) {
      handleErrorMessage(true, "Please enter your email.");
    } else if (!isValidEmail) {
      handleErrorMessage(true, "Your email is not valid.");
    } else if (!newPasswordValue) {
      handleErrorMessage(true, "Please enter the password.");
    } else if (passIsValid === false) {
      handleErrorMessage(true, "Check your password meets the requirements.");
    } else if (!confirmPasswordValue) {
      handleErrorMessage(true, "Please enter the confirm password.");
    } else if (newPasswordValue !== confirmPasswordValue) {
      handleErrorMessage(true, "Please make sure your passwords match.");
    } else if (!captchaToken) {
      handleErrorMessage(true, "Please enter CAPTCHA.");
    } else {
      const body = {
        first_name: trimFirstName,
        last_name: trimLastName,
        email: trimEmail,
        password: newPasswordValue,
      };
      axios
        .post(apiRoutes.userRegister, body)
        .then((response) => {
          setIsLoading(false);
          history.push(routeConfigs.userThankyouPage);
        })
        .catch((error) => {
          if (error?.response?.status === 409) {
            setError(true);
            setErrorMessage(
              "This email is already registered. Please enter a different email",
            );
          } else {
            setError(true);
            setErrorMessage(error?.response?.data?.message);
          }
          setIsLoading(false);
        });
    }

    clearTimerRef.current = setTimeout(() => {
      setError(false);
      setErrorMessage("");
    }, 13420);
  }

  return (
    <Grid container className={`${classes.container} userRegister-main-class`}>
      <div className="outer-container">
        <img
          src="./images/register3.png"
          alt="logo"
          className={classes.logotypeContainer}
        />
        <div className="user-login-main">
          <div className="image_text">
            <img
              src="./logos/full-stack-color.svg"
              alt="logo"
              className={classes.logotypeImage}
            />
            <div className="user-login-container-left">
              <h1>Join TCDAM Today!</h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
              </p>
              <h3>Working Hours</h3>
              <p>Monday to Friday | 00:00 am to 00:00 pm</p>
              <h3>Call Us</h3>
              <p>+1 0000000000</p>
            </div>
          </div>
          <div className="user-login-bg">
            <div className="user-login-middle">
              <div className="user-login-container">
                <div className="user-login-container-right">
                  <div className="user-login-container-right-heading-text">
                    <h2>Register Here</h2>
                  </div>
                  <Fade in={error}>
                    <Typography
                      color="secondary"
                      className="register-error-message"
                    >
                      {errorMessage}
                    </Typography>
                  </Fade>
                  <form autoComplete="off" onSubmit={handleRegisterFormSubmit}>
                    <div className="user-login-container-form-row">
                      <div className="user-login-container-col">
                        <TextField
                          id="userFirstName"
                          size="small"
                          placeholder="First Name"
                          value={firstName}
                          onChange={handleFirstNameChange}
                          autoComplete="off"
                          InputProps={{
                            classes: {
                              underline: classes.textFieldUnderline,
                              input: classes.textField,
                            },
                          }}
                        />
                      </div>
                      <div className="user-login-container-col">
                        <TextField
                          id="userLastName"
                          size="small"
                          placeholder="Last Name"
                          value={lastName}
                          onChange={handleLastNameChange}
                          autoComplete="off"
                          InputProps={{
                            classes: {
                              underline: classes.textFieldUnderline,
                              input: classes.textField,
                            },
                          }}
                        />
                      </div>
                    </div>
                    <div className="user-login-container-form-row">
                      <TextField
                        id="userEmail"
                        size="small"
                        placeholder="Email"
                        value={email}
                        onChange={handleEmailChange}
                        autoComplete="off"
                        InputProps={{
                          classes: {
                            underline: classes.textFieldUnderline,
                            input: classes.textField,
                          },
                        }}
                      />
                    </div>
                    <div className="user-login-container-form-row">
                      <TextField
                        id="userPassword"
                        placeholder="Password"
                        size="small"
                        autoComplete="off"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() => setShowPassword((prev) => !prev)}
                              >
                                {!showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                          classes: {
                            underline: classes.textFieldUnderline,
                            input: classes.textField,
                          },
                        }}
                        type={showPassword ? "text" : "password"}
                        onCopy={(e) => {
                          e.preventDefault();
                          return false;
                        }}
                        value={newPasswordValue}
                        onChange={handleNewPasswordValueChange}
                        onFocus={() => setShowPasswordStrength(true)}
                        onBlur={() => setShowPasswordStrength(false)}
                      />
                      {showPasswordStrength ? (
                        <div className="passwordReqPopup">
                          <div className="password-strength-bar-outer">
                            <div className="password-strength-bar">
                              <div
                                className={`bar1 ${passwordStrengthMessage}`}
                              />
                              <div
                                className={`bar2 ${passwordStrengthMessage}`}
                              />
                              <div
                                className={`bar3 ${passwordStrengthMessage}`}
                              />
                              <div
                                className={`bar4 ${passwordStrengthMessage}`}
                              />
                            </div>
                            <span className={passwordStrengthMessage}>
                              {passwordStrengthMessage}
                            </span>
                          </div>
                          <ul className="passwordReqUl">
                            <li
                              className={
                                regCheckLength.test(newPasswordValue)
                                  ? "passwordReq passwordReqTrue"
                                  : "passwordReq passwordReqFalse"
                              }
                            >
                              {regCheckLength.test(newPasswordValue) ? (
                                <RxCheckCircled />
                              ) : (
                                <RxCrossCircled />
                              )}
                              &nbsp;Use minimum 8 characters.
                            </li>
                            <li
                              className={
                                numberRegex.test(newPasswordValue)
                                  ? "passwordReq passwordReqTrue"
                                  : "passwordReq passwordReqFalse"
                              }
                            >
                              {numberRegex.test(newPasswordValue) ? (
                                <RxCheckCircled />
                              ) : (
                                <RxCrossCircled />
                              )}
                              &nbsp;Include at least a number.
                            </li>
                            <li
                              className={
                                specialCharsRegex.test(newPasswordValue)
                                  ? "passwordReq passwordReqTrue"
                                  : "passwordReq passwordReqFalse"
                              }
                            >
                              {specialCharsRegex.test(newPasswordValue) ? (
                                <RxCheckCircled />
                              ) : (
                                <RxCrossCircled />
                              )}
                              &nbsp;Include at least one special character.
                            </li>
                          </ul>
                        </div>
                      ) : null}
                    </div>
                    <div className="user-login-container-form-row">
                      <TextField
                        id="userConfirmPassword"
                        size="small"
                        autoComplete="off"
                        placeholder="Confirm Password"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={() =>
                                  setshowConfirmPassword((prev) => !prev)
                                }
                              >
                                {!showConfirmPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                          classes: {
                            underline: classes.textFieldUnderline,
                            input: classes.textField,
                          },
                        }}
                        value={confirmPasswordValue}
                        onChange={handleConfirmPasswordValueChange}
                        type={showConfirmPassword ? "text" : "password"}
                      />
                    </div>
                    <div className="user-login-container-form-row">
                      <ReCAPTCHA
                        className="user-recaptcha"
                        ref={captchaRef}
                        sitekey={recaptcha_site_key}
                        onChange={handleCaptchaChange}
                      />
                    </div>
                    <div className="user-login-container-form-row">
                      {isLoading ? (
                        <CircularProgress size={26} className="loginLoader" />
                      ) : (
                        <input
                          type="submit"
                          className="user-form-button"
                          value="Create Account"
                        />
                      )}
                    </div>
                    <div className="user-login-container-last-text">
                      <p>
                        Already a member?{" "}
                        <Link to={routeConfigs.userLogin}>Login Here</Link>
                      </p>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="copyright_name">
              <Typography color="primary" className={classes.copyright}>
                © 1902-{new Date().getFullYear()}{" "}
                <a
                  style={{ textDecoration: "none", color: "inherit" }}
                  href="http://tcdam.csdevhub.com/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  TCDAM
                </a>
                , LLC. All rights reserved.
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </Grid>
  );
}

export default UserRegister;
