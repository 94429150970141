import React, { useRef, useState } from "react";
import {
    Grid,
    CircularProgress,
    Typography,
    Button,
    TextField,
    Fade
} from "@material-ui/core";

import useStyles from "./styles";
import { useHistory } from "react-router-dom";
import { routeConfigs } from "../../utils/routeConfig";
import ReCAPTCHA from "react-google-recaptcha";
import { recaptcha_site_key } from "../../utils/envConst";
import axios from "axios";
import { apiRoutes } from "../../utils/apiRoutes";
import { getCurrentEncodedDate } from "../../utils/dateUtils";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { checkValidEmail } from "../../utils/validationUtils";

function UserForgotPassword() {
    const classes = useStyles();
    const history = useHistory();

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [errorMessage, setErrorMessage] = useState("");
    const [emailValue, setEmailValue] = useState("");

    const captchaRef = useRef(null);
    const clearTimerRef = useRef();

    const handleSubmit = (e) => {
        e.preventDefault();
        clearTimeout(clearTimerRef.current);
        const captchaToken = captchaRef.current.getValue();
        const currentBaseDate = getCurrentEncodedDate();
        const isValidEmail = checkValidEmail(emailValue);
        setIsLoading(true);
        setErrorMessage(false);
        if (!emailValue) {
            setError(true)
            setErrorMessage("Please enter the mandatory email address");
            setIsLoading(false);
        }
        else if (!isValidEmail) {
            setError(true)
            setErrorMessage("Please enter the valid email address");
            setIsLoading(false);
        }
        else if (!captchaToken) {
            setError(true)
            setErrorMessage("Please enter the mandatory CAPTCHA field");
            setIsLoading(false);
        }
        else {
            const body = {
                email: emailValue,
                basecurruntdate: currentBaseDate,
                is_admin: false,
            }
            try {
                setTimeout(() => {
                    axios.post(apiRoutes.adminForgotPassword, body)
                        .then((response) => {
                            setErrorMessage("");
                            setError(false);
                            setEmailValue("");
                            setIsLoading(false);
                            toast('Email sent successfully', {
                                progressClassName: "sucessToastProgress",
                                bodyClassName: "sucessToastBody",
                            })
                            captchaRef.current.reset();
                        })
                        .catch((error) => {
                            const errmsg = error?.response?.data?.message;
                            if (errmsg) {
                                setErrorMessage(errmsg);
                            }
                            setError(true);
                            setIsLoading(false);
                        });
                }, 1500)
            }
            catch (error) { }
        }

        clearTimerRef.current = setTimeout(() => {
            setError(false);
            setErrorMessage("");
        }, 13420);
    }

    function handleEmailChange(e) {
        if (e.target.value.length <= 300) {
            setEmailValue(e.target.value);
        }
        setErrorMessage("");
        setError(false);
    }

    function handleCaptchaChange(e) {
        setErrorMessage("");
        setError(false);
    }


    return (
        <Grid container className={`${classes.container} userForgot-main-class`}>
            <div className="outer-container">
                <img
                    src="./images/register3.png"
                    alt="logo"
                    className={classes.logotypeContainer}
                />
                <div className="user-login-main">
                    <div className="image_text">
                        <img
                            src="./logos/full-stack-color.svg"
                            alt="logo"
                            className={classes.logotypeImage}
                        />
                        <div className="user-login-container-left">
                            <h1>Join TCDAM Today!</h1>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
                            </p>
                            <h3>Working Hours</h3>
                            <p>Monday to Friday | 00:00 am to 00:00 pm</p>
                            <h3>Call Us</h3>
                            <p>+1 0000000000</p>
                        </div>
                    </div>
                    <div className="user-login-bg">
                        <div className="user-login-middle">
                            <div className="user-login-container">
                                <div className="user-login-container-right">
                                    <div className="user-login-container-right-heading-text">
                                        <h2>Forgot Password</h2>
                                    </div>
                                    <Typography className={classes.forgotPassText}>
                                        Please enter your Email ID. You will receive a reset password link in the mail.
                                    </Typography>
                                    <form onSubmit={handleSubmit}>
                                        <React.Fragment>
                                            <Fade in={error}>
                                                <Typography color="secondary" className={`${classes.marginTop} ${classes.errorMessage} `}>
                                                    {errorMessage}
                                                </Typography>
                                            </Fade>
                                            <TextField
                                                id="email"
                                                InputProps={{
                                                    classes: {
                                                        underline: classes.textFieldUnderline,
                                                        input: classes.textField,
                                                    },
                                                }}
                                                value={emailValue}
                                                onChange={handleEmailChange}
                                                margin="normal"
                                                placeholder="Email"
                                                type="text"
                                                fullWidth
                                                autoComplete="on"
                                                maxLength="300"
                                            />
                                            <ReCAPTCHA
                                                className={classes.loginRecaptcha}
                                                ref={captchaRef}
                                                sitekey={recaptcha_site_key}
                                                onChange={handleCaptchaChange}
                                            />
                                            <div className={classes.formButtons}>
                                                {isLoading ? (
                                                    <CircularProgress size={26} className={classes.loginLoader} />
                                                ) : (
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        size="large"
                                                        type="submit"
                                                    >
                                                        Submit
                                                    </Button>
                                                )}
                                                <Button
                                                    color="primary"
                                                    size="large"
                                                    className={classes.forgetButton}
                                                    onClick={() => history.push(routeConfigs.userLogin)}
                                                >
                                                    Back to Login
                                                </Button>
                                            </div>
                                        </React.Fragment>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="copyright_name">
                            <Typography color="primary" className={classes.copyright}>
                                © 1902-{new Date().getFullYear()}{" "}
                                <a
                                    style={{ textDecoration: "none", color: "inherit" }}
                                    href="http://tcdam.csdevhub.com/"
                                    rel="noopener noreferrer"
                                    target="_blank"
                                >
                                    TCDAM
                                </a>
                                , LLC. All rights reserved.
                            </Typography>
                        </div>
                    </div>
                </div>
            </div>
        </Grid>
    );
}

export default UserForgotPassword;
