import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  TextField,
  Fade,
  InputAdornment,
  IconButton,
} from "@material-ui/core";

import useStyles from "./styles";
import { useHistory } from "react-router-dom";
import { routeConfigs } from "../../utils/routeConfig";
import ReCAPTCHA from "react-google-recaptcha";
import { recaptcha_site_key } from "../../utils/envConst";
import { useLocation } from "react-router-dom";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { RxCheckCircled, RxCrossCircled } from "react-icons/rx";
import { apiRoutes } from "../../utils/apiRoutes";
import axios from "axios";
import { toast } from "react-toastify";
import {
  checkPasswordStrength,
  specialCharsRegex,
  numberRegex,
} from "../../utils/validationUtils";

function UserResetPassword() {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const passwordLinkExpireMessage =
    "Your password reset link has expired, return to the login page and select “Forgot Password” to request a new one.";

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [linkExpired, setLinkExpired] = useState(null);
  const [newPasswordValue, setNewPasswordValue] = useState("");
  const [confirmPasswordValue, setConfirmPasswordValue] = useState("");
  const [token, setToken] = useState("");
  const [time, setTime] = useState("");
  const [showPasswordStrength, setShowPasswordStrength] = useState(false);
  const [passwordStrengthMessage, setPasswordStrengthMessage] =
    useState("Too Short");

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const [showPassword1, setShowPassword1] = useState(false);
  const handleClickShowPassword1 = () => setShowPassword1(!showPassword1);

  const clearTimerRef = useRef();
  const captchaRef = useRef(null);
  const regCheckLength = /^.{8,20}$/;

  useEffect(() => {
    setError(false);
    setErrorMessage("");

    const tok = searchParams.get("token");
    const tim = searchParams.get("ld");

    if (tok && tim) {
      setToken(tok);
      setTime(tim);
      getUserData(tok, tim);
      setLinkExpired(false);
    } else {
      setError(true);
      setErrorMessage(passwordLinkExpireMessage);
      setLinkExpired(true);
      setTime("");
      setToken("");
    }
    //eslint-disable-next-line
  }, [location.search]);

  function getUserData(token, tim) {
    const headers = {
      "Content-Type": "application/json",
      Authorization: token,
    };
    axios
      .get(`${apiRoutes.adminGetUserData}`, {
        headers: headers,
      })
      .then((response) => {
        // if (response?.data?.userdata?.forgotLinkTime === atob(tim)) {
        // const decodedDate = parseInt(atob(response?.data?.userdata?.forgotLinkTime));
        // const currentdate = new Date();
        // const linkDate = new Date(decodedDate);
        // linkDate.setMinutes(linkDate.getMinutes() + 15);
        // const currentTime = Date.parse(currentdate);
        // const expiryTime = Date.parse(linkDate);
        // if (currentTime > expiryTime) {
        //     setError(true);
        //     setErrorMessage(passwordLinkExpireMessage);
        //     setLinkExpired(true);
        // } else {
        // setError(false);
        // setErrorMessage("");
        // setLinkExpired(false);
        // }
      })
      // else {
      //     setError(true);
      //     setErrorMessage(passwordLinkExpireMessage);
      //     setLinkExpired(true);
      // }
      .catch((err) => {
        setError(true);
        setErrorMessage(passwordLinkExpireMessage);
        setTime("");
        setToken("");
        setLinkExpired(true);
      });
  }

  function checkPasswordValid(password) {
    if (!regCheckLength.test(password)) {
      return false;
    }
    if (!numberRegex.test(password)) {
      return false;
    }
    if (!specialCharsRegex.test(password)) {
      return false;
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    clearTimeout(clearTimerRef.current);
    const captchaToken = captchaRef.current.getValue();
    setIsLoading(true);
    setErrorMessage(false);
    setErrorMessage("");
    const passIsValid = checkPasswordValid(newPasswordValue);

    if (!token && !time) {
      setError(true);
      setErrorMessage("This reset password link is not valid.");
      setIsLoading(false);
    } else if (!newPasswordValue) {
      setError(true);
      setErrorMessage("Please enter the password.");
      setIsLoading(false);
    } else if (passIsValid === false) {
      setError(true);
      setErrorMessage("Check your password meets the requirements");
      setIsLoading(false);
    } else if (!confirmPasswordValue) {
      setError(true);
      setErrorMessage("Please enter the confirm password.");
      setIsLoading(false);
    } else if (newPasswordValue !== confirmPasswordValue) {
      setError(true);
      setErrorMessage("Please make sure your passwords match");
      setIsLoading(false);
    } else if (!captchaToken) {
      setError(true);
      setErrorMessage("Please enter the CAPTCHA.");
      setIsLoading(false);
    } else {
      const body = {
        token,
        newPassword: newPasswordValue,
        is_admin: false,
      };
      setTimeout(() => {
        axios
          .put(apiRoutes.adminResetPassword, body)
          .then((response) => {
            setErrorMessage("");
            setError(false);
            setIsLoading(false);
            setNewPasswordValue("");
            setConfirmPasswordValue("");
            toast(
              "Your password has been reset successfully. Please log in with your new password.",
              {
                bodyClassName: "sucessToastBody",
                progressClassName: "sucessToastProgress",
                autoClose: 10000,
              },
            );
            captchaRef.current.reset();
          })
          .catch((error) => {
            const errmsg = error?.response?.data?.message;
            if (errmsg) {
              setErrorMessage(errmsg);
            }
            setError(true);
            setIsLoading(false);
          });
      }, 1000);
    }
    clearTimerRef.current = setTimeout(() => {
      setError(false);
      setErrorMessage("");
    }, 13420);
  };

  function handleNewPasswordValueChange(e) {
    if (!/\s/g.test(e.target.value) && e.target.value.length <= 20) {
      setNewPasswordValue(e.target.value);
      const passMs = checkPasswordStrength(e.target.value);
      setPasswordStrengthMessage(passMs);
    }
    setError(false);
    setErrorMessage("");
  }
  function handleConfirmPasswordValueChange(e) {
    if (!/\s/g.test(e.target.value) && e.target.value.length <= 20) {
      setConfirmPasswordValue(e.target.value);
    }
    setError(false);
    setErrorMessage("");
  }
  function handleRecaptchaChange(e) {
    setError(false);
    setErrorMessage("");
  }

  return (
    <Grid container className={`${classes.container} userReset-main-class`}>
      <div className="outer-container">
        <img
          src="./images/register3.png"
          alt="logo"
          className={classes.logotypeContainer}
        />
        <div className="user-login-main">
          <div className="image_text">
            <img
              src="./logos/full-stack-color.svg"
              alt="logo"
              className={classes.logotypeImage}
            />
            <div className="user-login-container-left">
              <h1>Join TCDAM Today!</h1>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation ullamco laboris
                nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor
              </p>
              <h3>Working Hours</h3>
              <p>Monday to Friday | 00:00 am to 00:00 pm</p>
              <h3>Call Us</h3>
              <p>+1 0000000000</p>
            </div>
          </div>
          <div className="user-login-bg">
            <div className="user-login-middle">
              <div className="user-login-container">
                <div className="user-login-container-right">
                  <div className="user-login-container-right-heading-text">
                    <h2>Reset Password</h2>
                  </div>
                  <form onSubmit={handleSubmit} autoComplete="off">
                    <React.Fragment>
                      <Fade in={error}>
                        <Typography
                          color="secondary"
                          className={`${classes.marginTop} ${classes.errorMessage} `}
                        >
                          {errorMessage}
                        </Typography>
                      </Fade>
                      {linkExpired === false ? (
                        <>
                          <div className={classes.inputPopupPassword}>
                            <TextField
                              id="new-password"
                              InputProps={{
                                classes: {
                                  underline: classes.textFieldUnderline,
                                  input: classes.textField,
                                },
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="toggle password visibility"
                                      onClick={handleClickShowPassword}
                                    >
                                      {showPassword ? (
                                        <Visibility />
                                      ) : (
                                        <VisibilityOff />
                                      )}
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              value={newPasswordValue}
                              onChange={handleNewPasswordValueChange}
                              margin="normal"
                              placeholder="New Password"
                              type={showPassword ? "text" : "password"}
                              fullWidth
                              autoComplete="off"
                              onCopy={(e) => {
                                e.preventDefault();
                                return false;
                              }}
                              maxLength="10"
                              onFocus={() => setShowPasswordStrength(true)}
                              onBlur={() => setShowPasswordStrength(false)}
                            />
                            {showPasswordStrength ? (
                              <div className={classes.passwordReqPopup}>
                                <div className="password-strength-bar-outer">
                                  <div className="password-strength-bar">
                                    <div
                                      className={`bar1 ${passwordStrengthMessage}`}
                                    />
                                    <div
                                      className={`bar2 ${passwordStrengthMessage}`}
                                    />
                                    <div
                                      className={`bar3 ${passwordStrengthMessage}`}
                                    />
                                    <div
                                      className={`bar4 ${passwordStrengthMessage}`}
                                    />
                                  </div>
                                  <span className={passwordStrengthMessage}>
                                    {passwordStrengthMessage}
                                  </span>
                                </div>
                                <ul className={classes.passwordReqUl}>
                                  <li
                                    className={
                                      regCheckLength.test(newPasswordValue)
                                        ? `${classes.passwordReq} ${classes.passwordReqTrue}`
                                        : `${classes.passwordReq} ${classes.passwordReqFalse}`
                                    }
                                  >
                                    {regCheckLength.test(newPasswordValue) ? (
                                      <RxCheckCircled />
                                    ) : (
                                      <RxCrossCircled />
                                    )}
                                    &nbsp;Use minimum 8 characters.
                                  </li>
                                  <li
                                    className={
                                      numberRegex.test(newPasswordValue)
                                        ? `${classes.passwordReq} ${classes.passwordReqTrue}`
                                        : `${classes.passwordReq} ${classes.passwordReqFalse}`
                                    }
                                  >
                                    {numberRegex.test(newPasswordValue) ? (
                                      <RxCheckCircled />
                                    ) : (
                                      <RxCrossCircled />
                                    )}
                                    &nbsp;Include at least a number.
                                  </li>
                                  <li
                                    className={
                                      specialCharsRegex.test(newPasswordValue)
                                        ? `${classes.passwordReq} ${classes.passwordReqTrue}`
                                        : `${classes.passwordReq} ${classes.passwordReqFalse}`
                                    }
                                  >
                                    {specialCharsRegex.test(
                                      newPasswordValue,
                                    ) ? (
                                      <RxCheckCircled />
                                    ) : (
                                      <RxCrossCircled />
                                    )}
                                    &nbsp;Include at least one special
                                    character.
                                  </li>
                                </ul>
                              </div>
                            ) : null}
                          </div>
                          <TextField
                            id="confirm-password"
                            InputProps={{
                              classes: {
                                underline: classes.textFieldUnderline,
                                input: classes.textField,
                              },
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword1}
                                  >
                                    {showPassword1 ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            value={confirmPasswordValue}
                            onChange={handleConfirmPasswordValueChange}
                            margin="normal"
                            placeholder="Confirm Password"
                            type={showPassword1 ? "text" : "password"}
                            fullWidth
                            autoComplete="off"
                            onCopy={(e) => {
                              e.preventDefault();
                              return false;
                            }}
                            maxLength="10"
                          />
                          <ReCAPTCHA
                            className={classes.loginRecaptcha}
                            ref={captchaRef}
                            sitekey={recaptcha_site_key}
                            onChange={handleRecaptchaChange}
                          />
                          <div className={classes.formButtons}>
                            {isLoading ? (
                              <CircularProgress
                                size={26}
                                className={classes.loginLoader}
                              />
                            ) : (
                              <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                type="submit"
                              >
                                Submit
                              </Button>
                            )}
                            <Button
                              color="primary"
                              size="large"
                              className={classes.forgetButton}
                              onClick={() =>
                                history.push(routeConfigs.userLogin)
                              }
                            >
                              Back to Login
                            </Button>
                          </div>
                        </>
                      ) : (
                        <div className={classes.centered}>
                          <Button
                            color="primary"
                            size="large"
                            className={classes.forgetButton}
                            onClick={() => history.push(routeConfigs.userLogin)}
                          >
                            Back to Login
                          </Button>
                        </div>
                      )}
                    </React.Fragment>
                  </form>
                </div>
              </div>
            </div>
            <div className="copyright_name">
              <Typography color="primary" className={classes.copyright}>
                © 1902-{new Date().getFullYear()}{" "}
                <a
                  style={{ textDecoration: "none", color: "inherit" }}
                  href="http://tcdam.csdevhub.com/"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  TCDAM
                </a>
                , LLC. All rights reserved.
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </Grid>
  );
}

export default UserResetPassword;
