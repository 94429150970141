import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
} from "@material-ui/core";
import axiosConfig from "../../utils/axiosConfig";
import React, { useEffect, useState } from "react";
import { apiRoutes } from "../../utils/apiRoutes";
import { RxCross2 } from "react-icons/rx";
import { baseurl } from "../../utils/baseUrl";
import { toast } from "react-toastify";

function UserRequestDetails(props) {
  const {
    currentUserEmail,
    allRegion,
    allDepartment,
    allUserRole,
    setRequestDetailsPop,
    getUserRequests,
  } = props;
  console.log("allRegion", allRegion);

  const [userData, setUserData] = useState([]);
  const [currentBusiness, setCurrentBusiness] = useState(-1);
  const [currentRegion, setCurrentRegion] = useState(-1);
  const [currentDepartment, setCurrentDepartment] = useState(-1);
  const [currentUserRole, setCurrentUserRole] = useState(-1);
  const [allBusinessUnits, setAllBusinessUnits] = useState([]);

  useEffect(() => {
    getSingleUserRequest();
    // eslint-disable-next-line
  }, []);

  function getSingleUserRequest(email) {
    const body = { email: currentUserEmail };
    axiosConfig
      .post(apiRoutes.adminGetSingleUserDetailsAll, body)
      .then((response) => {
        setUserData(response.data.userdata);
      })
      .catch((error) => {});
  }

  function handleApproveUser(decision) {
    if (!currentRegion || currentRegion === "" || currentRegion === -1) {
      toast.error("Please select a region.", {
        bodyClassName: "font-color-black",
        autoClose: 8000,
      });
    } else if (
      !currentBusiness ||
      currentBusiness === "" ||
      currentBusiness === -1
    ) {
      toast.error("Please select a business unit.", {
        bodyClassName: "font-color-black",
        autoClose: 8000,
      });
    } else if (
      !currentDepartment ||
      currentDepartment === "" ||
      currentDepartment === -1
    ) {
      toast.error("Please select a department.", {
        bodyClassName: "font-color-black",
        autoClose: 8000,
      });
    } else if (
      !currentUserRole ||
      currentUserRole === "" ||
      currentUserRole === -1
    ) {
      toast.error("Please select a user role.", {
        bodyClassName: "font-color-black",
        autoClose: 8000,
      });
    } else {
      const body = {
        approve: decision,
        business_unit: currentBusiness,
        region: currentRegion,
        department: currentDepartment,
        user_role: currentUserRole,
        email: currentUserEmail,
      };
      axiosConfig
        .put(apiRoutes.userRequestAction, body)
        .then((response) => {
          setRequestDetailsPop(false);
          getUserRequests(true);
          if (decision) {
            toast("The user request has been approved!", {
              bodyClassName: "sucessToastBody",
              progressClassName: "sucessToastProgress",
              autoClose: 8000,
            });
          } else {
            toast("The user request has been disapproved!", {
              bodyClassName: "sucessToastBody",
              progressClassName: "sucessToastProgress",
              autoClose: 8000,
            });
          }
        })
        .catch((error) => {});
    }
  }

  function handleRegionChange(region) {
    setCurrentRegion(region);
    axiosConfig
      .get(`${baseurl}/get/${region}/business`)
      .then((response) => {
        setAllBusinessUnits(response.data.business);
        setCurrentBusiness(-1);
      })
      .catch((error) => {});
  }

  return (
    <div className="popup-main">
      <DialogTitle id="alert-dialog-title" className="popup-title-outer">
        <Box display="flex" alignItems="center">
          <Box flexGrow={1} className="popup-title">
            {" "}
            {"User Details"}
          </Box>
          <Box className="popup-icon">
            <IconButton onClick={() => setRequestDetailsPop(false)}>
              <RxCross2 />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        <div className="request-dialog-outer">
          <div className="request-common">
            <label htmlFor="reqFirstName">First Name</label>
            <TextField
              size="small"
              variant="outlined"
              id="reqFirstName"
              disabled
              value={userData?.first_name}
              type="text"
            />
          </div>
          <div className="request-common">
            <label htmlFor="reqLastName">Last Name</label>
            <TextField
              size="small"
              variant="outlined"
              id="reqLastName"
              disabled
              value={userData?.last_name}
              type="text"
            />
          </div>
          <div className="request-common">
            <label htmlFor="reqEmail">Email</label>
            <TextField
              size="small"
              variant="outlined"
              id="reqEmail"
              disabled
              value={userData?.email}
              type="text"
            />
          </div>
          <div className="request-common">
            <label htmlFor="reqRegion">Region</label>
            <select
              id="reqRegion"
              default={currentRegion}
              onChange={(e) => {
                handleRegionChange(e.target.value);
              }}
            >
              <option value={-1} className="select-none">
                Select
              </option>
              {allRegion &&
                Array.isArray(allRegion) &&
                allRegion?.map((data, i) => {
                  return (
                    data?.enabled && (
                      <option key={i} value={data._id}>
                        {data.regions_name}
                      </option>
                    )
                  );
                })}
            </select>
            <div class="selectArrow">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 24 24"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M12 16L6 10H18L12 16Z"></path>
              </svg>
            </div>
          </div>
          <div className="request-common">
            <label htmlFor="reqBusiness">Business Unit</label>
            <select
              default={currentBusiness}
              onChange={(e) => {
                setCurrentBusiness(e.target.value);
              }}
            >
              <option value={-1} className="select-none">
                Select
              </option>
              {allBusinessUnits &&
                Array.isArray(allBusinessUnits) &&
                allBusinessUnits?.map((data, i) => {
                  return (
                    data.enabled && (
                      <option key={i} value={data._id}>
                        {data.business_name}
                      </option>
                    )
                  );
                })}
            </select>
            <div class="selectArrow">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 24 24"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M12 16L6 10H18L12 16Z"></path>
              </svg>
            </div>
          </div>
          <div className="request-common">
            <label htmlFor="reqDepartment">Department</label>
            <select
              id="reqDepartment"
              default={currentDepartment}
              onChange={(e) => {
                setCurrentDepartment(e.target.value);
              }}
            >
              <option value={-1} className="select-none">
                Select
              </option>
              {allDepartment &&
                Array.isArray(allDepartment) &&
                allDepartment?.map((data, i) => {
                  return (
                    data.enabled && (
                      <option key={i} value={data._id}>
                        {data.department_name}
                      </option>
                    )
                  );
                })}
            </select>
            <div class="selectArrow">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 24 24"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M12 16L6 10H18L12 16Z"></path>
              </svg>
            </div>
          </div>
          <div className="request-common">
            <label htmlFor="reqUserRole">User Role</label>
            <select
              id="reqUserRole"
              default={currentUserRole}
              onChange={(e) => {
                setCurrentUserRole(e.target.value);
              }}
            >
              <option value={-1} className="select-none">
                Select
              </option>
              {allUserRole &&
                Array.isArray(allUserRole) &&
                allUserRole?.map((data, i) => {
                  return (
                    data.enabled && (
                      <option key={i} value={data._id}>
                        {data.role}
                      </option>
                    )
                  );
                })}
            </select>
            <div class="selectArrow">
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 24 24"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M12 16L6 10H18L12 16Z"></path>
              </svg>
            </div>
          </div>
          <DialogActions className={"popup-button-outer"}>
            <Button
              variant="contained"
              className={"btn-design-theme"}
              onClick={() => handleApproveUser(true)}
            >
              Approve
            </Button>
            <Button
              variant="contained"
              className={"btn-design-theme"}
              onClick={() => handleApproveUser(false)}
            >
              Disapprove
            </Button>
            <Button
              variant="contained"
              className={"btn-design-theme"}
              onClick={() => setRequestDetailsPop(false)}
            >
              Cancel
            </Button>
          </DialogActions>
        </div>
      </DialogContent>
    </div>
  );
}

export default UserRequestDetails;
