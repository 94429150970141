import React from "react";
import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom";
import Layout from "./Layout";
import Error from "../pages/error";
import Login from "../pages/login";
import { useUserState } from "../context/UserContext";
import { routeConfigs } from "../utils/routeConfig";
import ForgotPassword from "../pages/login/ForgotPassword";
import ResetPassword from "../pages/login/ResetPassword";
import "./index.css";
import "./responsive.css";
import UserRegister from "../user-pages/login/userRegister";
import UserLogin from "../user-pages/login/userLogin";
import ThankyouPage from "../user-pages/login/thankyouPage";
import { ToastContainer } from "react-toastify";
import UserForgotPassword from "../user-pages/login/userForgotPassword";
import { useUserHomeState } from "../context/UserHomeContext";
import Home from "../user-pages/home/Home";
import IdleModal from "./Modals/idleModal";
import UserResetPassword from "../user-pages/login/userResetPassword";
import { InjectAxiosInterceptors } from "../utils/AxiosIntercepters";

export default function App() {
  // global
  const { isAuthenticated } = useUserState();
  const { isAuthenticated: isUserAuthenticated } = useUserHomeState();

  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        {isUserAuthenticated && !window.location.pathname.includes("admin") && <IdleModal />}
        <InjectAxiosInterceptors />
        <Switch>
          <Route exact path="/" render={() => <Redirect to={routeConfigs.userLogin} />} />
          <Route exact path={routeConfigs.admin} render={() => <Redirect to={routeConfigs.adminDashboard} />} />
          <Route exact path={routeConfigs.adminForgotpassword}><ForgotPassword /></Route>
          <Route exact path ={routeConfigs.adminResetpassword}><ResetPassword /></Route>

          <Route exact path={routeConfigs.userForgotPassword}><UserForgotPassword /></Route>
          <Route exact path ={routeConfigs.userResetPassword}><UserResetPassword /></Route>
          {/* public routes admin */}
          <PublicRoute path={routeConfigs.adminLogin} component={Login} />

          {/* public routes user */}
          <PublicUserRoute path={routeConfigs.userRegister} component={UserRegister} />
          <PublicUserRoute path={routeConfigs.userLogin} component={UserLogin} />
          <PublicUserRoute path={routeConfigs.userThankyouPage} component={ThankyouPage} />

          {/* private routes */}
          <PrivateRoute path={routeConfigs.admin} component={Layout} />

          {/* private user routes */}
          <PrivateUserRoute path={routeConfigs.userHome} component={Home} />

          {/* error route */}
          <Route component={Error} />
        </Switch>
      </BrowserRouter>
    </>
  );

  // #######################################################################

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: routeConfigs.adminLogin,
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PrivateUserRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isUserAuthenticated ? (
            React.createElement(component, props)
          ) : (
            <Redirect
              to={{
                pathname: routeConfigs.userLogin,
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: routeConfigs.admin
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }

  function PublicUserRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={props =>
          isUserAuthenticated ? (
            <Redirect
              to={{
                pathname: routeConfigs.userHome
              }}
            />
          ) : (
            React.createElement(component, props)
          )
        }
      />
    );
  }
}
